import axios from "axios"
if (localStorage.getItem("userAuthScolarite")) {
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var token = userAuthScolarite.user.api_token
}
if (localStorage.getItem("anneeScolaire")) {
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.Annee.id
}
export default axios.create({
  withCredentials: true,
  baseURL: `https://scolarite.backcresus-institut.ovh/api`,
  headers: {
    authorization: `Bearer ${token}`,
    apipassword: "3KbmGc5tZhuDbPtb",
    'X-Frame-Options': 'DENY',
    annee:annee,
    "Access-Control-Allow-Origin":"*"
  },
})
