import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditMatiereModule = props => {
  const [matiere, setMatiere] = useState([])
  const [modules, setModules] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [moduleMatiere, setMouduleMatiere] = useState("")
  const [loading, setLoading] = useState(false)
  //

  useEffect(async () => {
    const resM = await API.get("matiere/select").then(resM => {
      setMatiere(resM.data.Matieres)
    })
    //
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("matiere/get_affectation", { id }).then(res => {
      setModules(res.data.affectation.module)
      setSelectMatiere(res.data.affectation.matiere)
      setMouduleMatiere(res.data.affectation.module_matiere_id)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("matiere/update_affectation", {
      module_id: id,
      module_matiere_id: moduleMatiere,
      matieres: selectMatiere,
    }).then(res => {
      props.history.push("/Matiere")
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Module :
                          </Label>
                          <Select
                            isSearchable={true}
                            value={modules}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Matière :
                          </Label>
                          <Select
                            options={matiere}
                            isMulti={true}
                            isSearchable={true}
                            value={selectMatiere}
                            onChange={e => setSelectMatiere(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Matiere")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditMatiereModule)
EditMatiereModule.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
