import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {Col, Row, Label, Form, Button, Container, Card, CardBody, CardTitle} from "reactstrap"
// Toastify
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {withTranslation} from "react-i18next"

const ListEmergementVierge = props => {


    const [matiere, setMatiere] = useState([])
    const [selectMatiere, setSelectMatiere] = useState()

    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
    ])
    const [enseignant, setEnseignant] = useState([])
    const [quart, setQuart] = useState([])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [selectCycle, setSelectCycle] = useState("")
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [selectNiveau, setSelectNiveau] = useState("")
    const [niveau, setNiveau] = useState([])
    const [specialite, setSpecialite] = useState([])
    const [cycle, setCycle] = useState([])
    const [date, setDate] = useState([]);
    const [selectDate, setSelectDate] = useState("");
    var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
    var annee_id = anneeScolaire.Annee.id
    useEffect(async () => {
        // Matiere
        const resM = await API.get("matiere/select").then(res => {
            setMatiere(res.data.Matieres)
        })
        // cycle
        const res = await API.get("cycle/select").then(res => {
            setCycle(res.data.Cycle)
        })
        //niveau
        const resNiveau = await API.get("niveau/select").then(resNiveau => {
            setNiveau(resNiveau.data.Niveau)
        })
    }, [])

    const getSpecialite = async event => {
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectDate("")
        setSelectSemestre(event)
        const res = await API.post("specialite/get_by_niveaux_cycle_semestre", {
            semestre_id: event.value,
            niveau_id: selectNiveau.value,
            cycle_id: selectCycle.value,
        }).then(res => {
            setSpecialite(res.data.Specialites)
        })
    }

    const getMatiere = async event => {
        setSelectSpecialite(event)
        setSelectMatiere("")
        setSelectDate("")
        const res = await API.post(
            "matiere/get_by_cycle_niveaux_semestre_specialites",
            {
                specialite_id: event.value,
                semestre_id: selectSemestre.value,
                niveau_id: selectNiveau.value,
                cycle_id: selectCycle.value,
            }
        ).then(res => {
            setMatiere(res.data.Matiere)
        })
    }
    const getDate = async event => {
        setSelectMatiere(event)
        const res = await API.post(
            "matiere/get_date",
            {
                matiere_id: event.value,
                semestre_id: selectSemestre.value,
                niveau_id: selectNiveau.value,
                cycle_id: selectCycle.value,
                specialite_id: selectSpecialite.value,
            }
        ).then(res => {
            setDate(res.data.date)
        })
    }
    const uploadPDFGroup = async () => {
        var cycle_id = selectCycle != "" && selectCycle != null ? selectCycle.value : 0
        var niveau_id = selectNiveau != "" && selectNiveau != null ? selectNiveau.value : 0
        var semestre_id = selectSemestre != "" && selectSemestre != null ? selectSemestre.value : 0
        var specialite_id = selectSpecialite != "" && selectSpecialite != null ? selectSpecialite.value : 0
        var matiere_id = selectMatiere != "" && selectMatiere != null ? selectMatiere.value : 0
        var salle_id = selectDate != "" && selectDate != null ? selectDate.label : 0
        if (cycle_id == 0 || niveau_id == 0 || semestre_id == 0|| specialite_id == 0|| matiere_id == 0|| salle_id == 0) {
            setLoading(true)
            toast.error("⛔  Tous les champs sont obligatoire pour le exportation !", {
                containerId: "A",
            })
        } else {
            window.open('https://scolarite.backcresus-institut.ovh/api/impression_list_vierge_emargement_ds2/'
                + cycle_id + '/' + niveau_id + '/' + semestre_id + '/' + specialite_id+ '/' + matiere_id+ '/' + annee_id + '/' + salle_id , '_blank')
        }
    }
    return (
        <React.Fragment>
            <Container fluid>
                <Card>
                    <CardBody>
                        <Row>
                            <Form>
                                <CardTitle style={{color: "#556ee6"}} className="h4">
                                    Critéres de Recherches :
                                </CardTitle>
                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Cycle</Label>
                                            <Select
                                                options={cycle}
                                                isSearchable={true}
                                                value={selectCycle}
                                                onChange={e => setSelectCycle(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Niveau</Label>
                                            <Select
                                                options={niveau}
                                                isSearchable={true}
                                                value={selectNiveau}
                                                onChange={e => setSelectNiveau(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Semestre:</Label>
                                            <Select
                                                options={semestre}
                                                isSearchable={true}
                                                value={selectSemestre}
                                                onChange={e => getSpecialite(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Spécialité</Label>
                                            <Select
                                                options={specialite}
                                                isSearchable={true}
                                                value={selectSpecialite}
                                                onChange={e => getMatiere(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                {props.t("Matière")}
                                            </Label>
                                            <Select
                                                options={matiere}
                                                isSearchable={true}
                                                value={selectMatiere}
                                                onChange={e => getDate(e)}
                                                placeholder={props.t("Select")}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                {props.t("Salle")}
                                            </Label>
                                            <Select
                                                options={date}
                                                isSearchable={true}
                                                value={selectDate}
                                                onChange={e => setSelectDate(e)}
                                                placeholder={props.t("Select")}
                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="text-center mt-4">
                                            <a className="btn btn-success mb-2 me-2" onClick={uploadPDFGroup}
                                               style={{backgroundColor: "#761C19", borderColor: "#761C19"}}
                                               target="_blank"
                                               rel="noreferrer"
                                            ><i
                                                style={{
                                                    color: "#red",
                                                    cursor: "pointer",
                                                    fontSize: "15px",
                                                    margin: "0px 6px 0px 0px"
                                                }}
                                                className="fas fa-file-pdf"
                                            ></i>
                                                Exportation Liste Vierge PDF
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </CardBody>
                </Card>
                <ToastContainer
                    transition={Slide}
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={2500}
                />
            </Container>
        </React.Fragment>
    )
}

export default withTranslation()(ListEmergementVierge)
ListEmergementVierge.propTypes = {
    history: PropTypes.object,
    setSection: PropTypes.object,
    back: PropTypes.object,
    t: PropTypes.any,
}
