import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const EditEnseignant = props => {
  const [id, setId] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [gender, setGender] = useState("")
  const [grade, setGrade] = useState([])
  const [selectGrade, setSelectGrade] = useState("")
  const [date, setDate] = useState(new Date())
  const [type, setType] = useState(1)
  const [etatCivil, setEtatCivil] = useState(1)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [pays, setPays] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [file, setFile] = useState(null)
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [loading, setLoading] = useState(false)
  const [structure, setStructure] = useState("")
  const [statut, setStatut] = useState([])
  const [selectStatut, setSelectStatut] = useState("")
  const options = useMemo(() => countryList().getData(), [])

  const editDate = date => {
    setDate(date)
  }

  const addFile = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    //   Département
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
    // Statut
    const resS = await API.get("statut/select").then(resS => {
      setStatut(resS.data.Statut)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await API.post("getById_enseignant", { id }).then(res => {
      setNom(res.data.Enseignant.firstName)
      setPrenom(res.data.Enseignant.lastName)
      setGender(res.data.Enseignant.gender)
      setNumero(res.data.Enseignant.code)
      setSelectNationnalite(res.data.Enseignant.nationality)
      setDate(new Date(res.data.Enseignant.birthdate))
      setLieuNaissance(res.data.Enseignant.birthplace)
      setEmail(res.data.Enseignant.email)
      setTelephone(res.data.Enseignant.telephone)
      setSelectGrade(res.data.Enseignant.grade)
      setType(res.data.Enseignant.type)
      setRue(res.data.Enseignant.addressStreet)
      setCodePostal(res.data.Enseignant.addressZipCode)
      setPays(res.data.Enseignant.addressCountry)
      setGouvernorat(res.data.Enseignant.addressState)
      setEtatCivil(res.data.Enseignant.civilState)
      setSelectDepartement(res.data.Enseignant.departement)
      setLoading(true)
    })
    const resG = await API.get("grade/select").then(resG => {
      setGrade(resG.data.Grade)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (date != "") {
      let dateComm = date
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //Test si file vide
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    var nationalite = selectNationnalite.label
    var grade_id = selectGrade.value
    if (nom == "" && prenom == "" && numero == "" && telephone == "") {
      toast.error("⛔ Remplir les champs obligatoires", {
        containerId: "A",
      })
    } else if (date != "") {
      const res = await API.post("enseignant/update", {
        id: id,
        active: 0,
        firstName: nom,
        lastName: prenom,
        gender: gender,
        type: type,
        code: numero,
        nationality: nationalite,
        birthplace: lieuNaissance,
        addressStreet: rue,
        addressZipCode: codePostal,
        addressCountry: pays,
        addressState: gouvernorat,
        email: email,
        telephone: telephone,
        birthdate: convertDate,
        grade_id: grade_id,
        departement_id: selectDepartement,
        civilState: etatCivil,
        statut_id: selectStatut.value,
        fichier: pathFile,
        structure: structure,
      }).then(res => {
        props.history.push("/Enseignant")
        if (pathFile != "") {
          const formData = new FormData()
          formData.append("document", file)
          const resA = API.post("file/import", formData).then(resA => {})
        }
      })
    } else {
      toast.error("⛔  Date obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <AvForm>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Nom :</Label>
                          <AvField
                            name="nom"
                            placeholder="Entrer nom"
                            type="text"
                            errorMessage="* nom obligatoire"
                            className="form-control"
                            value={nom}
                            validate={{ required: { value: true } }}
                            onChange={e => setNom(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Prénom :
                          </Label>
                          <AvField
                            name="prenom"
                            placeholder="Entrer prénom"
                            type="text"
                            errorMessage="* prenom obligatoire"
                            className="form-control"
                            value={prenom}
                            validate={{ required: { value: true } }}
                            onChange={e => setPrenom(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="d-block mb-3">Sexe :</Label>
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline1"
                              name="customRadioInline1"
                              className="form-check-input"
                              value={gender}
                              checked={gender === 1}
                              onClick={() => setGender(1)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline1"
                            >
                              Masculin
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="form-check-input"
                              checked={gender === 2}
                              onClick={() => setGender(2)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline2"
                            >
                              Feminin
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nationalité :
                          </Label>
                          <Select
                            options={options}
                            isSearchable={true}
                            value={selectNationnalite}
                            onChange={e => setSelectNationnalite(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="d-block mb-3">Type :</Label>
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline3"
                              name="customRadioInline3"
                              className="form-check-input"
                              checked={type === 1}
                              onClick={() => setType(1)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline3"
                            >
                              CIN
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline4"
                              name="customRadioInline3"
                              className="form-check-input"
                              checked={type === 2}
                              onClick={() => setType(2)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline4"
                            >
                              Passeport
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Identifiant :{" "}
                          </Label>
                          <AvField
                            name="numero"
                            placeholder="Entrer identifiant"
                            type="text"
                            errorMessage="Min 8 caractère"
                            className="form-control"
                            value={numero}
                            validate={{
                              required: { value: true },
                              min: { value: 8 },
                            }}
                            onChange={e => setNumero(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Date de Naissance :{" "}
                          </Label>
                          <Col md={12} className="pr-0">
                            <DatePicker
                              className="form-control ddate"
                              selected={date}
                              onChange={editDate}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Lieu de Naissance :
                          </Label>
                          <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer lieu"
                            type="text"
                            value={lieuNaissance}
                            onChange={e => setLieuNaissance(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="d-block mb-3">Etat Civil :</Label>
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline5"
                              name="customRadioInline5"
                              className="form-check-input"
                              checked={etatCivil === 1}
                              onChange={e => setEtatCivil(1)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline5"
                            >
                              Célibataire
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline6"
                              name="customRadioInline5"
                              className="form-check-input"
                              checked={etatCivil === 2}
                              onChange={e => setEtatCivil(2)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline6"
                            >
                              Marié(e)
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline7"
                              name="customRadioInline5"
                              className="form-check-input"
                              checked={etatCivil === 3}
                              onChange={e => setEtatCivil(3)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline7"
                            >
                              Divorcé(e)
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Photo :
                          </Label>
                          <Input
                            onChange={e => addFile(e)}
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Rue : </Label>
                          <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer rue"
                            type="text"
                            value={rue}
                            onChange={e => setRue(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Code postal :
                          </Label>
                          <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer code postal"
                            type="number"
                            value={codePostal}
                            onChange={e => setCodePostal(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Pays :{" "}
                          </Label>
                          <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer pays"
                            type="text"
                            value={pays}
                            onChange={e => setPays(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Gouvernorat :
                          </Label>
                          <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer gouvernorat"
                            type="text"
                            value={gouvernorat}
                            onChange={e => setGouvernorat(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="E-Mail"
                            placeholder="Entrer un email valide"
                            type="email"
                            errorMessage="Email invalide"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Téléphone :
                          </Label>
                          <AvField
                            name="tel"
                            placeholder="Entrer téléphone"
                            type="text"
                            errorMessage="* Téléphone obligatoire"
                            className="form-control"
                            value={telephone}
                            validate={{
                              required: { value: true },
                              min: { value: 8 },
                            }}
                            onChange={e => setTelephone(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Grade :{" "}
                          </Label>
                          <Select
                            options={grade}
                            isSearchable={true}
                            value={selectGrade}
                            onChange={e => setSelectGrade(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Département :
                          </Label>
                          <Select
                            isSearchable={true}
                            options={departement}
                            value={selectDepartement}
                            onChange={e => setSelectDepartement(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Statut :{" "}
                          </Label>
                          <Select
                            isSearchable={true}
                            options={statut}
                            onChange={e => setSelectStatut(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Structure de recherche (Unité de recherche ou
                            Laboratoire de recherche) :{" "}
                          </Label>
                          <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer Structure de recherche"
                            type="text"
                            onChange={e => setStructure(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Enseignant")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="submit"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </AvForm>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(EditEnseignant)
EditEnseignant.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
