import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Historique
import Historique from "../pages/Historique/Historique"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Cycle
import Cycle from "../pages/Cycle/Cycle"
import EditCycle from "../pages/Cycle/EditCycle"

// Niveau
import Niveau from "../pages/Niveau/Niveau"
import EditNiveau from "../pages/Niveau/EditNiveau"

import Tirage from "../pages/Tirage/Tirage"

// Specialite
import Specialite from "../pages/Specialite/Specialite"
import EditSpecialite from "../pages/Specialite/EditSpecialite"

// Groupe
import Groupe from "../pages/Groupe/Groupe"
import EditGroupe from "../pages/Groupe/EditGroupe"
import DetailGroupe from "../pages/Groupe/DetailGroupe"
import ViewGroupCour from "../pages/Groupe/ViewGroupCour"
import ViewGroupTD from "../pages/Groupe/ViewGroupTD"
import ViewGroupTP from "../pages/Groupe/ViewGroupTP"

// Module
import Module from "../pages/Modules/Module"
import EditModuleSpec from "../pages/Modules/EditModuleSpec"
import EditModule from "../pages/Modules/EditModule"
import DetailModuleSpecialite from "../pages/Modules/DetailModuleSpecialite"

// Matiere
import Matiere from "../pages/Matieres/Matiere"
import EditMatiere from "../pages/Matieres/EditMatiere"
import EditMatiereModule from "../pages/Matieres/EditMatiereModule"
import DetailMatiereModule from "../pages/Matieres/DetailMatiereModule"
import MatieresEnseignants from "../pages/Matieres/MatieresEnseignants"

// Enseignant
import Enseignant from "../pages/Enseignant/Enseignant"
import EditEnseignant from "../pages/Enseignant/EditEnseignant"
import DetailEnseignants from "../pages/Enseignant/DetailEnseignants"
import EnseignantsGroupes from "../pages/Enseignant/EnseignantsGroupes"
import FicheVoeux from "../pages/Enseignant/FicheVoeux"

// Grade Enseignant
import Grade from "../pages/Grades/Grade"
import EditGrade from "../pages/Grades/EditGrade"

// Statut Enseignant
import Statut from "../pages/Statuts/Statut"
import EditStatut from "../pages/Statuts/EditStatut"

// Département
import Departement from "../pages/Departement/Departement"
import EditDepartement from "../pages/Departement/EditDepartement"
import EditAffectation from "../pages/Departement/EditAffectation"


// Etudiant
import Etudiant from "../pages/Etudiant/Etudiant"
import EditEtudiant from "../pages/Etudiant/EditEtudiant"
import DetailEtudiant from "../pages/Etudiant/DetailEtudiant"

// SchedulesEnseignants
import SchedulesEnseignants from "../pages/CalendarEnseignant/SchedulesEnseignants"
import AddScheduleEnseignant from "../pages/CalendarEnseignant/AddScheduleEnseignant"
import EmploiEnseignant from "../pages/CalendarEnseignant/EmploiEnseignant"

//emploi group
import EmploiGroup from "../pages/CalendarGroupes/EmploiGroup"
import SchedulesGroups from "../pages/CalendarGroupes/SchedulesGroups"

// Emploi salle
import ScheduleSalle from "../pages/CalendarSalle/ScheduleSalle"
import EmploiSalle from "../pages/CalendarSalle/EmploiSalle"

// Calendar Exam
import CalExam from "../pages/CalendarExamen/CalExam"
import ListMatieresExam from "../pages/CalendarExamen/ListMatieresExam"

// Calendar DS2
import CalDS2 from "../pages/DS2/CalDS2"
import ListMatieresDS2 from "../pages/DS2/ListMatieresDS2"

// Compostage
import Compostage from "../pages/Compostage/Compostage"
import CompostageCon from "../pages/Compostage/CompostageCon"
import ListMatieres from "../pages/Compostage/ListMatieres"
import ListSalle from "../pages/Compostage/ListSalle"

// Note examen
import NoteExamen from "pages/NoteExamen/NoteExamen"
import NoteExamenCon from "pages/NoteExamen/NoteExamenCon"
import ListMatieresNote from "../pages/NoteExamen/ListMatieresNote"
import ListEtudiantNote from "../pages/NoteExamen/ListEtudiantNote"

// DS2
import NoteDS2 from "pages/NoteDS2/NoteDS2"
import ListMatieresNoteDS2 from "../pages/NoteDS2/ListMatieresNoteDS2"
import ListMatieresNoteDS2Deux from "../pages/NoteDS2/ListMatieresNoteDS2Deux"
import ListEtudiantNoteDS2 from "../pages/NoteDS2/ListEtudiantNoteDS2"
import ListEtudiantNoteDS2Deux from "../pages/NoteDS2/ListEtudiantNoteDS2Deux"


// Surveillance
import Surveillance from "../pages/Surveillance/Surveillance"

import SurveillanceJour from "../pages/SurveillanceJour/SurveillanceJour"

//Salle
import Salle from "../pages/Salle/Salle"
import EditSalle from "../pages/Salle/EditSalle"

//Demande
import AttestationPresence from "../pages/Demandes/AttestationPresence"

// note
import NoteDS from "pages/NoteDS/NoteDS"
import AddNoteDS from "pages/NoteDS/AddNoteDS"

// ListAbsence
import ListAbsence from "pages/ListAbsence/ListAbsence"
import EnseignantAbsence from "pages/ListAbsence/EnseignantAbsence"
import Seance from "pages/ListAbsence/Seance"
import EditAbsence from "pages/ListAbsence/EditAbsence"

//
import Rattrapage from "pages/Rattrapage/Rattrapage"


import AvisTest from "pages/Test/AvisTest"

// Espace Mastere
import ListInscription from "../pages/EspaceMastere/Inscription/ListInscription"
import FicheVoeuxEns from "pages/Enseignant/FicheVoeuxEns"
import AttestationPresenceArchive from "../pages/Demandes/AttestationPresenceArchive";
import QuartExamen from "../pages/QuartExamen/QuartExamen";
import Registre from "../pages/Registre/Registre";
import ResultatExamen from "../pages/ResultatExamen/ResultatExamen";
import PlanEtude from "../pages/PlanEtude/PlanEtude";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // Registre
  { path: "/Registre", component: Registre },
  // profile
  { path: "/profile", component: UserProfile },

  // Historique
  { path: "/Historique", component: Historique },
  // PlanEtude
  { path: "/PlanEtude", component: PlanEtude },

  // Cycle
  { path: "/Cycle", component: Cycle },
  { path: "/EditCycle", component: EditCycle },

  // Niveau
  { path: "/Niveau", component: Niveau },
  { path: "/EditNiveau", component: EditNiveau },
  // Resultat examen
  { path: "/ResultatExamen", component: ResultatExamen },


  // Specialite
  { path: "/Specialite", component: Specialite },
  { path: "/EditSpecialite", component: EditSpecialite },

  // Groupe
  { path: "/Groupe", component: Groupe },
  { path: "/EditGroupe", component: EditGroupe },
  { path: "/DetailGroupe", component: DetailGroupe },
  { path: "/ViewGroupCour", component: ViewGroupCour },
  { path: "/ViewGroupTD", component: ViewGroupTD },
  { path: "/ViewGroupTP", component: ViewGroupTP },

  // Module
  { path: "/Module", component: Module },
  { path: "/EditModuleSpec", component: EditModuleSpec },
  { path: "/EditModule", component: EditModule },
  { path: "/DetailModuleSpecialite", component: DetailModuleSpecialite },

  // Matiere
  { path: "/Matiere", component: Matiere },
  { path: "/EditMatiere", component: EditMatiere },
  { path: "/EditMatiereModule", component: EditMatiereModule },
  { path: "/DetailMatiereModule", component: DetailMatiereModule },
  { path: "/MatieresEnseignants", component: MatieresEnseignants },

  // Enseignant
  { path: "/Enseignant", component: Enseignant },
  { path: "/EditEnseignant", component: EditEnseignant },
  { path: "/DetailEnseignants", component: DetailEnseignants },
  { path: "/EnseignantsGroupes", component: EnseignantsGroupes },
  { path: "/FicheVoeux", component: FicheVoeux },
  { path: "/FicheVoeuxEns", component: FicheVoeuxEns },

  // Grade Enseignant
  { path: "/Grade", component: Grade },
  { path: "/EditGrade", component: EditGrade },

  // Statut Enseignant
  { path: "/Statut", component: Statut },
  { path: "/EditStatut", component: EditStatut },

  // Département
  { path: "/Departement", component: Departement },
  { path: "/EditDepartement", component: EditDepartement },
  { path: "/EditAffectation", component: EditAffectation },


  // Etudiant
  { path: "/Etudiant", component: Etudiant },
  { path: "/EditEtudiant", component: EditEtudiant },
  { path: "/DetailEtudiant", component: DetailEtudiant },

  // SchedulesEnseignants
  { path: "/SchedulesEnseignants", component: SchedulesEnseignants },
  { path: "/AddScheduleEnseignant", component: AddScheduleEnseignant },
  { path: "/EmploiEnseignant", component: EmploiEnseignant },

  //emploi group
  { path: "/EmploiGroup", component: EmploiGroup },
  { path: "/SchedulesGroups", component: SchedulesGroups },

  //emploi salle
  { path: "/ScheduleSalle", component: ScheduleSalle },
  { path: "/EmploiSalle", component: EmploiSalle },

  // Calendar Exam
  { path: "/CalExam", component: CalExam },
  { path: "/ListMatieresExam", component: ListMatieresExam },

  // Calendar ds2
  { path: "/CalDS2", component: CalDS2 },
  { path: "/ListMatieresDS2", component: ListMatieresDS2 },

  // Compostage
  { path: "/Compostage", component: Compostage },
  { path: "/CompostageCon", component: CompostageCon },
  { path: "/ListMatieres", component: ListMatieres },
  { path: "/ListSalle", component: ListSalle },

  // Surveillance
  { path: "/Surveillance", component: Surveillance },

  { path: "/SurveillanceJour", component: SurveillanceJour },

  // QuartExamen
  { path: "/QuartExamen", component: QuartExamen },

  //Salle
  { path: "/Salle", component: Salle },
  { path: "/EditSalle", component: EditSalle },

  //Demande
  { path: "/AttestationPresence", component: AttestationPresence },
  { path: "/AttestationPresenceArchive", component: AttestationPresenceArchive },

  // note
  { path: "/NoteDS", component: NoteDS },
  { path: "/AddNoteDS", component: AddNoteDS },
// note
  { path: "/NoteDS2", component: NoteDS2 },
  // { path: "/AddNoteDS", component: AddNoteDS },

  // Absence
  { path: "/ListAbsence", component: ListAbsence },
  { path: "/EnseignantAbsence", component: EnseignantAbsence },
  { path: "/Seance", component: Seance },
  { path: "/EditAbsence", component: EditAbsence },

  //
  { path: "/Rattrapage", component: Rattrapage },

  { path: "/Tirage", component: Tirage },

  { path: "/AvisTest", component: AvisTest },

  // NoteExamenPrincipale
  { path: "/NoteDS2", component: NoteDS2 },
  { path: "/ListMatieresNoteDS2", component: ListMatieresNoteDS2 },
  { path: "/ListMatieresNoteDS2Deux", component: ListMatieresNoteDS2Deux },

  { path: "/ListEtudiantNoteDS2", component: ListEtudiantNoteDS2 },
  { path: "/ListEtudiantNoteDS2Deux", component: ListEtudiantNoteDS2Deux },

    // DS2
    { path: "/NoteExamen", component: NoteExamen },
    { path: "/NoteExamenCon", component: NoteExamenCon },
    { path: "/ListMatieresNote", component: ListMatieresNote },
    { path: "/ListEtudiantNote", component: ListEtudiantNote },
  

  // List Inscription
  { path: "/ListInscription", component: ListInscription },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/ResetPassword/:token", component: ResetPassword },
]

export { publicRoutes, authProtectedRoutes }
