import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Col, Input, Row, Label, Form, Button } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"

const AddNiveau = props => {
  const [niveau, setNiveau] = useState("")

  const save = async () => {
    if (niveau != "") {
      const res = await API.post("niveau/add", {
        name: niveau,
      })
        .then(res => {
          props.setSection(1)
        })
        .catch(() => {
          toast.error("⛔  Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("⛔  Niveau obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Niveau")}
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Niveau")}
                  onChange={e => setNiveau(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddNiveau)
AddNiveau.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
