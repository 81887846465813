import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Card,
  CardBody,
  Spinner,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import classnames from "classnames"

const DetailGroupe = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [selectSemestre, setSelectSemestre] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [effectif, setEffectif] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [nbrTD, setNbrTD] = useState("")
  const [nbrTP, setNbrTP] = useState("")
  const [arrayGroupe, setArrayGroupe] = useState([])
  const [arrayGroupeTD, setArrayGroupeTD] = useState([])
  const [arrayGroupeTP, setArrayGroupeTP] = useState([])
  const [loading, setLoading] = useState(true)
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("group/get_by_id", { id }).then(res => {
      setSelectNiveau(res.data.Group.niveau_id)
      setSelectSemestre(res.data.Group.semestre_id)
      setSelectSpecialite(res.data.Group.specialite_id)
      setEffectif(res.data.Group.effectif)
      setNbrCours(res.data.Group.nbrCours)
      setNbrTD(res.data.Group.nbrTD)
      setNbrTP(res.data.Group.nbrTP)
      setArrayGroupe(res.data.Group.groupsCours)
      setArrayGroupeTD(res.data.Group.groupsTD)
      setArrayGroupeTP(res.data.Group.arrayGroupeTP)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Semestre:
                          </Label>
                          <Select
                            isSearchable={true}
                            isMulti={true}
                            value={selectSemestre}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Niveau</Label>
                          <Select isSearchable={true} value={selectNiveau} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Spécialité
                          </Label>
                          <Select
                            isSearchable={true}
                            value={selectSpecialite}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Effectif :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Effectif"
                            type="number"
                            value={effectif}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe cours :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe cours"
                            type="number"
                            value={nbrCours}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe TD :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe TD"
                            type="number"
                            value={nbrTD}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe TP :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe TP"
                            type="number"
                            value={nbrTP}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          Groupes de cours
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <span className="d-none d-sm-block">Groupes de TD</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          setactiveTab("3")
                        }}
                      >
                        <span className="d-none d-sm-block">Groupes de TP</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <CardTitle className="h4 mt-4">Groupe de cours</CardTitle>
                      <table className="table table-bordered mt-4">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }} scope="col">
                              Identifiant groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Nom groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Type groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Capacité
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Nombre etudiant
                            </th>
                          </tr>
                        </thead>
                        {arrayGroupe.map((el, index) => (
                          <tbody key={index}>
                            <tr>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Identifiant Groupe"
                                  value={el.identifiantGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Nom Groupe"
                                  value={el.NomGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type Groupe"
                                  value={el.TypeGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  placeholder="Capacité"
                                  value={el.capacite}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  placeholder="Nombre Etudiant"
                                  value={el.nombreEtudiant}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </TabPane>
                    <TabPane tabId="2">
                      <CardTitle className="h4 mt-4">Groupe de TD</CardTitle>
                      <table className="table table-bordered mt-4">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }} scope="col">
                              Identifiant groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Nom groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Type groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Capacité
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Nombre etudiant
                            </th>
                          </tr>
                        </thead>
                        {arrayGroupeTD.map((el, index) => (
                          <tbody key={index}>
                            <tr>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Identifiant Groupe"
                                  value={el.identifiantGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Nom Groupe"
                                  value={el.NomGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type Groupe"
                                  value={el.TypeGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  placeholder="Capacité"
                                  value={el.capacite}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  placeholder="Nombre Etudiant"
                                  value={el.nombreEtudiant}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </TabPane>
                    <TabPane tabId="3">
                      <CardTitle className="h4 mt-4">Groupe de TP</CardTitle>
                      <table className="table table-bordered mt-4">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }} scope="col">
                              Identifiant groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Nom groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Type groupe
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Capacité
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Nombre etudiant
                            </th>
                          </tr>
                        </thead>
                        {arrayGroupeTP.map((el, index) => (
                          <tbody key={index}>
                            <tr>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Identifiant Groupe"
                                  value={el.identifiantGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Nom Groupe"
                                  value={el.NomGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type Groupe"
                                  value={el.TypeGroupe}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  placeholder="Capacité"
                                  value={el.capacite}
                                />
                              </td>
                              <td className="col-md-2">
                                <Input
                                  type="number"
                                  className="form-control"
                                  placeholder="Nombre Etudiant"
                                  value={el.nombreEtudiant}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </TabPane>
                  </TabContent>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Groupe")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DetailGroupe
DetailGroupe.propTypes = {
  history: PropTypes.object,
}
