import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

const DetailModuleSpecialite = props => {
  const [module, setModule] = useState([])
  const [selectModule, setSelectModule] = useState("")
  const [selectCycle, setSelectCycle] = useState("")
  const [selectDiplome, setSelectDiplome] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [loading, setLoading] = useState(false)
  //
  useEffect(async () => {
    const resM = await API.get("module/select").then(resM => {
      setModule(resM.data.Module)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("module/get_by_id_affectation", { id }).then(
      res => {
        setSelectModule(res.data.Module.module)
        setSelectCycle(res.data.Module.cycle)
        setSelectDiplome(res.data.Module.diplome)
        setSelectNiveau(res.data.Module.niveau)
        setSelectSpecialite(res.data.Module.specialite)
        setLoading(true)
      }
    )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Cycle :
                          </Label>
                          <Select isSearchable={true} value={selectCycle} />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Diplôme :
                          </Label>
                          <Select isSearchable={true} value={selectDiplome} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Spécialité :
                          </Label>
                          <Select
                            isSearchable={true}
                            value={selectSpecialite}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Niveau :
                          </Label>
                          <Select isSearchable={true} value={selectNiveau} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label
                            style={{ color: "#556ee6" }}
                            for="basicpill-firstname-input1"
                          >
                            Module :
                          </Label>
                          <Select
                            options={module}
                            isMulti={true}
                            isSearchable={true}
                            value={selectModule}
                            onChange={e => setSelectNiveau(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Module")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DetailModuleSpecialite)
DetailModuleSpecialite.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
