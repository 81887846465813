import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import PropTypes from "prop-types"
import API from "../../api"

const Seance = props => {
  const state_element = props.location.state.element
  const enseignant_id = props.location.state.enseignant_id
  const [listDate, setListDate] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const resE = await API.post("seance/get_date_seance", {
      enseignant_id: enseignant_id,
      matiere_id: state_element.id_matiere,
      type: state_element.type,
    }).then(resE => {
      setListDate(resE.data.SeanceDate)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      {/* type = 1 => moyenne
      type = 2 note */}
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Row className="mb-4 mt-4">
              <Col sm="4">
                <div className="d-flex">
                  <h4>Matière :</h4>
                  <h4 style={{ paddingLeft: "5px", color: "#556ee6" }}>
                    {state_element.label_matiere}
                  </h4>
                </div>
              </Col>
              <Col sm="8">
                <div className="text-sm-end">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={() =>
                      props.history.push({
                        pathname: "/EditAbsence",
                        state: {
                          element: state_element,
                          enseignant_id: enseignant_id,
                          type: 1,
                        },
                      })
                    }
                  >
                    Consulter moyenne
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              {listDate.length != 0 ? (
                listDate.map((element, key) => (
                  <Col
                    onClick={() =>
                      props.history.push({
                        pathname: "/EditAbsence",
                        state: {
                          element: state_element,
                          date: element.date,
                          enseignant_id: enseignant_id,
                          type: 2,
                        },
                      })
                    }
                    xl="3"
                    sm="4"
                    key={"_col_" + key}
                  >
                    <Card>
                      <CardBody style={{ cursor: "pointer" }}>
                        <div className="mt-2 text-center cursor-pointer">
                          <h5>{element.date}</h5>
                        </div>
                        <div className="mt-4 text-center">
                          <h5>{state_element.label_matiere}</h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (
                <Card>
                  <CardBody>
                    <h4 className="text-center mt-4">
                      Aucune seance pour le moment 😉
                    </h4>
                  </CardBody>
                </Card>
              )}
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default Seance
Seance.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}
