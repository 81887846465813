import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddEtudiant from "./AddEtudiant"
import DataTableEtudiant from "./DataTableEtudiant"
import ExportEtudiant from "./ExportEtudiant"
import ImportEtudiant from "./ImportEtudiant"
import Orientation from "./Orientation"
import EtudiantMastere from "./EtudiantMastere";
import DataTableMastere from "./DataTableMastere";

const Etudiant = props => {
  const [section, setSection] = useState(1)

  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableEtudiant />
    } else if (section === 2) {
      return <AddEtudiant setSection={setSection} back={back} />
    } else if (section === 3) {
      return <ExportEtudiant setSection={setSection} back={back} />
    } else if (section === 4) {
      return <ImportEtudiant setSection={setSection} back={back} />
    } else if (section === 5) {
      return <Orientation setSection={setSection} back={back} />
    }else if(section ===6){
      return <EtudiantMastere setSection={setSection} back={back}/>
    }else if(section ===7){
      return <DataTableMastere setSection={setSection} back={back}/>
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Etudiant | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Etudiant
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 2,
                  })}
                  onClick={() => {
                    setSection(2)
                  }}
                >
                  Ajouter etudiant
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 3,
                  })}
                  onClick={() => {
                    setSection(3)
                  }}
                >
                  Exportation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 4,
                  })}
                  onClick={() => {
                    setSection(4)
                  }}
                >
                  Importation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 5,
                  })}
                  onClick={() => {
                    setSection(5)
                  }}
                >
                  Orientation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 6,
                  })}
                  onClick={() => {
                    setSection(6)
                  }}
                >
                  Mastère
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 7,
                  })}
                  onClick={() => {
                    setSection(7)
                  }}
                >
                  Document Mastère
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Etudiant)
