import React, { useState } from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import DetailCalExam from "./DetailCalExam"

const DetailCalExamSem = props => {
  const [section, setSection] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1", section: 0 },
    { value: 2, label: "Semestre 2", section: 1 },
  ])

  const renderBlock = section => {
    for (let i = 0; i < semestre.length; i++) {
      if (section === i) {
        return (
          <DetailCalExam session={props.session} semestre={semestre[i].value} />
        )
      }
    }
  }

  return (
    <React.Fragment>
      <Nav className="icon-tab nav-justified">
        {semestre.map((element, index) => (
          <NavItem key={index}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: section === element.section,
              })}
              onClick={() => {
                setSection(element.section)
              }}
            >
              <span className="d-none d-sm-block">{element.label}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {renderBlock(section)}
    </React.Fragment>
  )
}

export default DetailCalExamSem
DetailCalExamSem.propTypes = {
  history: PropTypes.object,
  session: PropTypes.object,
}
