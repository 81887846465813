import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import classnames from "classnames";
import ListResultatEtudiant from "./ListResultatEtudiant";

const DataTableResultatExamen = props => {
    const [semestre, setSemestre] = useState([
        { value: 1, label: "Semestre 1", section: 0 },
        { value: 2, label: "Semestre 2", section: 1 },
        { value: 3, label: "Contrôle", section: 2 },
    ])
    const [section, setSection] = useState("")

    const renderBlock = section => {
        for (let i = 0; i < semestre.length; i++) {
            if (section === i) {
                return <ListResultatEtudiant semestre={semestre[i]} session={1} />
            }
        }
    }
    useEffect(() => {
        renderBlock()
    }, [])
    return (
        <React.Fragment>
            <Nav className="icon-tab nav-justified mt-3 mb-3">
                {semestre.map((element, index) => (
                    <NavItem key={index}>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: section === element.section,
                            })}
                            onClick={() => {
                                setSection(element.section)
                            }}
                        >
                            <span className="d-none d-sm-block">{element.label}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {renderBlock(section)}
        </React.Fragment>
    )

}

export default withRouter(withTranslation()(DataTableResultatExamen))
DataTableResultatExamen.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    semestre: PropTypes.object,
    session: PropTypes.object,
    t: PropTypes.any,
}
