import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import countryList from "react-select-country-list"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import { Button, CardTitle, Col, Form, Input, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const AddEtudiant = props => {
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [prenomPere, setPrenomPere] = useState("")
  const [telPere, setTelPere] = useState("")
  const [gender, setGender] = useState(1)
  const [type, setType] = useState(1)
  const [numero, setNumero] = useState("")
  const [date, setDate] = useState("")
  const [selectNationality, setSelectNationality] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [etatCivil, setEtatCivil] = useState(1)
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [pays, setPays] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState("")
  const [mention, setMention] = useState("")
  const [moyenne, setMoyenne] = useState("")
  const [session, setSession] = useState("")
  const [paysObtention, setPaysObtention] = useState("")
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [selectDiplome, setSelectDiplome] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [file, setFile] = useState(null)
  const options = useMemo(() => countryList().getData(), [])

  useEffect(async () => {
    const resC = await API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    if (cycle_id == 1) {
      const res = await API.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await API.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }

  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }

  const getGroupe = async event => {
    var specialite_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {
    if (date != "") {
      let dateComm = date
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if (selectCycle == "") {
      toast.error("⛔ Veuillez choisir un cycle", {
        containerId: "A",
      })
    }  else if (selectSpecialite == "") {
      toast.error("⛔ Veuillez choisir une spécialité", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Veuillez choisir un niveau", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      toast.error("⛔ Veuillez choisir un groupe", {
        containerId: "A",
      })
    }  else if (nom != "" && prenom != "" && numero != "") {
      const res = await API.post("etudiant/add", {
        firstName: nom,
        lastName: prenom,
        gender: gender,
        type: type,
        code: numero,
        nationality: selectNationality.label,
        email: email,
        birthdate: convertDate,
        birthplace: lieuNaissance,
        telephone: tel,
        group_id: selectGroupe.value,
        fatherName: prenomPere,
        fatherPhone: telPere,
        civilState: etatCivil,
        addressStreet: rue,
        addressZipCode: codePostal,
        addressCountry: pays,
        addressState: gouvernorat,
        bacSection: section,
        bacMention: mention,
        bacSession: session,
        bacMedium: moyenne,
        bacYear: anneeBac,
        photo: pathFile,
        bacCountry: paysObtention,
        active: 0,
      }).then(res => {
        props.setSection(1)
        if (pathFile != "") {
          const formData = new FormData()
          formData.append("document", file)
          formData.append("annee", "2021")
          formData.append("entite", "etudiant")
          formData.append("type", "photo")
          formData.append("id", numero)
          const resA = API.post("photo/store", formData)
        }
      })
    } else {
      toast.error("⛔  Remplir les champs obligatoires", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
              Informations générales :
            </CardTitle>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Nom :</Label>
                  <AvField
                    name="nom"
                    placeholder="Entrer nom"
                    type="text"
                    errorMessage="* nom obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setNom(e.target.value)}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Prénom :</Label>
                  <AvField
                    name="prenom"
                    placeholder="Entrer prénom"
                    type="text"
                    errorMessage="* prenom obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setPrenom(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Prénom du père :
                  </Label>
                  <Input
                    lg="3"
                    className="form-control glyphicon glyphicon-star"
                    placeholder="Entrer prénom du père"
                    type="text"
                    onChange={e => setPrenomPere(e.target.value)}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Téléphone du père :
                  </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer Téléphone de père"
                    type="number"
                    onChange={e => setTelPere(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="d-block mb-3">Sexe :</Label>
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      className="form-check-input"
                      checked={gender === 1}
                      onChange={e => setGender(1)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline1"
                    >
                      Masculin
                    </Label>
                  </div>
                  &nbsp;
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline1"
                      className="form-check-input"
                      checked={gender === 2}
                      onChange={e => setGender(2)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline2"
                    >
                      Féminin
                    </Label>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Nationalité :</Label>
                  <Select
                    options={options}
                    isSearchable={true}
                    onChange={e => setSelectNationality(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="d-block mb-3">Type :</Label>
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline3"
                      name="customRadioInline3"
                      className="form-check-input"
                      checked={type === 1}
                      onChange={e => setType(1)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline3"
                    >
                      CIN
                    </Label>
                  </div>
                  &nbsp;
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline4"
                      name="customRadioInline3"
                      className="form-check-input"
                      checked={type === 2}
                      onChange={e => setType(2)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline4"
                    >
                      Passeport
                    </Label>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Identifiant :</Label>
                  <AvField
                    name="numero"
                    placeholder="Entrer numéro"
                    type="text"
                    errorMessage="Min 8 caractère"
                    className="form-control"
                    validate={{ required: { value: true }, min: { value: 8 } }}
                    onChange={e => setNumero(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Date de Naissance :{" "}
                  </Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={date}
                      onChange={e => setDate(e)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Lieu de Naissance :
                  </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer lieu de naissance"
                    type="text"
                    onChange={e => setLieuNaissance(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="d-block mb-3">Etat Civil :</Label>
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline5"
                      name="customRadioInline5"
                      className="form-check-input"
                      checked={etatCivil === 1}
                      onChange={e => setEtatCivil(1)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline5"
                    >
                      Célibataire
                    </Label>
                  </div>
                  &nbsp;
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline6"
                      name="customRadioInline5"
                      className="form-check-input"
                      checked={etatCivil === 2}
                      onChange={e => setEtatCivil(2)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline6"
                    >
                      Marié(e)
                    </Label>
                  </div>
                  &nbsp;
                  <div className="form-check form-check-inline">
                    <Input
                      type="radio"
                      id="customRadioInline7"
                      name="customRadioInline5"
                      className="form-check-input"
                      checked={etatCivil === 3}
                      onChange={e => setEtatCivil(3)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="customRadioInline7"
                    >
                      Divorcé(e)
                    </Label>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Photo :</Label>
                  <Input
                    onChange={e => addFile(e)}
                    className="form-control"
                    type="file"
                    id="formFile"
                  />
                </div>
              </Col>
            </Row>
            <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
              Adresse :
            </CardTitle>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Rue : </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer rue"
                    type="text"
                    onChange={e => setRue(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Code postal :</Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer code postal"
                    type="number"
                    onChange={e => setCodePostal(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Pays : </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer pays"
                    type="text"
                    value={pays}
                    onChange={e => setPays(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Gouvernorat :</Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer gouvernorat"
                    type="text"
                    onChange={e => setGouvernorat(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Téléphone : </Label>
                  <AvField
                    name="tel"
                    placeholder="Entrer téléphone"
                    type="text"
                    errorMessage="* Téléphone obligatoire"
                    className="form-control"
                    validate={{ required: { value: true }, min: { value: 8 } }}
                    onChange={e => setTel(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="E-Mail"
                    placeholder="Entrer un email valide"
                    type="email"
                    errorMessage="Email invalide"
                    onChange={e => setEmail(e.target.value)}
                    validate={{
                      required: { value: true },
                      email: { value: true },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
              Baccalauréat :
            </CardTitle>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Année du bac :{" "}
                  </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer année du bac"
                    type="text"
                    onChange={e => setAnneeBac(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Section :</Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer section"
                    type="text"
                    onChange={e => setSection(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Moyenne : </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer moyenne"
                    type="number"
                    onChange={e => setMoyenne(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Mention : </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer mention"
                    type="text"
                    onChange={e => setMention(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Session :</Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer session"
                    type="text"
                    onChange={e => setSession(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {`Pays d'obtention`} :{" "}
                  </Label>
                  <Input
                    lg="3"
                    className="form-control"
                    placeholder="Entrer pays d'obtention"
                    type="text"
                    onChange={e => setPaysObtention(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
              Diplôme :
            </CardTitle>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Cycle : </Label>
                  <Select
                    options={cycle}
                    isSearchable={true}
                    value={selectCycle}
                    onChange={e => getNiveau(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Niveau : </Label>
                  <Select
                    options={niveau}
                    isSearchable={true}
                    value={selectNiveau}
                    onChange={e => getSpec(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Spécialité :</Label>
                  <Select
                    options={specialite}
                    isSearchable={true}
                    value={selectSpecialite}
                    onChange={e => getGroupe(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Groupe : </Label>
                  <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => setSelectGroupe(e)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  onClick={save}
                  type="submit"
                  className="btn btn-primary "
                >
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddEtudiant
AddEtudiant.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
