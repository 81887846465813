import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import DataTableSchedulesGrp from "./DataTableSchedulesGrp"

const SchedulesGroups = props => {
  const [section, setSection] = useState(0)
  const [semestre, setSemestre] = useState([
    { id: 1, label: "Emploi groupe Semestre 1", section: 0 },
    { id: 2, label: "Emploi groupe Semestre 2", section: 1 },
  ])

  const renderBlock = section => {
    for (let i = 0; i < semestre.length; i++) {
      if (section === i) {
        return <DataTableSchedulesGrp semestre={semestre[i].id} />
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Emploi Groupe | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              {semestre.map((element, index) => (
                <NavItem key={index}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === element.section,
                    })}
                    onClick={() => {
                      setSection(element.section)
                    }}
                  >
                    <span className="d-none d-sm-block">{element.label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SchedulesGroups)
