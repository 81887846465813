import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import API from "../../../api"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notfications, setNotfications] = useState([
    // {
    //   id: 1,
    //   image: `${avatar3}`,
    //   title: "James Lemire",
    //   description: "It will seem like simplified English",
    //   time: "3 min ago",
    // },
    // {
    //   id: 2,
    //   image: `${avatar3}`,
    //   title: "James Lemire",
    //   description: "It will seem like simplified English",
    //   time: "3 min ago",
    // },
    // {
    //   id: 3,
    //   image: `${avatar3}`,
    //   title: "James Lemire",
    //   description: "It will seem like simplified English",
    //   time: "3 min ago",
    // },
  ])

  useEffect(async () => {
    const res = await API.get("notification/list").then(res => {
      setNotfications(res.data.notification)
    })
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i style={{ color: "white" }} className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{notfications.length}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "250px" }}>
            {notfications.map(element => (
              <Link
                key={element.id}
                to=""
                className="text-reset notification-item"
              >
                <div className="media">
                  <img
                    src={avatar3}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{props.t(`${element.title}`)}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {/* {console.log(
                          "element.description",
                          element.description
                        )} */}
                        {element.description}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {props.t(`${element.time}`)}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
