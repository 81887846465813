import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"

const EditNiveau = props => {
  const [niveau, setNiveau] = useState("")

  //

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("getById_niveau", { id }).then(res => {
      setNiveau(res.data.Niveau.name)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (niveau != "") {
      const res = await API.post("niveau/update", {
        id: id,
        name: niveau,
      })
        .then(res => {
          props.history.push("/Niveau")
        })
        .catch(() => {
          toast.error("⛔  Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("⛔  Niveau obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Form>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Niveau")}
                        </Label>
                        <Input
                          lg="3"
                          type="text"
                          className="form-control"
                          placeholder={props.t("Niveau")}
                          value={niveau}
                          onChange={e => setNiveau(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={() => props.history.push("/Niveau")}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                    >
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={edit}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </Col>
                </div>
              </Row>
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditNiveau)
EditNiveau.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
