import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddEnseignant from "./AddEnseignant"
import DataTableEnseignant from "./DataTableEnseignant"
import ExportEnsiegnant from "./ExportEnsiegnant"
import ImportEnseignant from "./ImportEnseignant"
import FicheVoeauxList from "./FicheVoeauxList";

const Enseignant = props => {
  const [section, setSection] = useState(1)

  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableEnseignant/>
    } else if (section === 2) {
      return <AddEnseignant setSection={setSection} back={back} />
    } else if (section === 3) {
      return <ExportEnsiegnant/>
    } else if (section === 4) {
      return <ImportEnseignant/>
    } else if (section === 5) {
      return <FicheVoeauxList/>
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Enseignant | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Enseignant
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 2,
                  })}
                  onClick={() => {
                    setSection(2)
                  }}
                >
                  Ajouter enseignant
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 3,
                  })}
                  onClick={() => {
                    setSection(3)
                  }}
                >
                  Exportation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 4,
                  })}
                  onClick={() => {
                    setSection(4)
                  }}
                >
                  Importation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 5,
                    })}
                    onClick={() => {
                      setSection(5)
                    }}
                >
                  Fiche Voeaux
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Enseignant)
