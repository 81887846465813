import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import FileDownload from "js-file-download";

const ListResultatEtudiant = props => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 100,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{ textAlign: "center" }}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "etudiant",
            text: props.t("Etudiant"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "cin",
            text: props.t("CIN"),
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Téléchargement",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a
                    onClick={() => download(row.file)}
                    target="_blank"
                    rel="noopener noreferrer"
                   style={{ display: "flex", justifyContent: "center" }}
                >
                    <i
                        style={{ color: "#955cd5", cursor: "pointer" }}
                        className="fas fa-file-download"
                    ></i>
                </a>
            ),
        },
        {
            dataField: "Suppression",
            isDummyField: true,
            text: "Suppression",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) =>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <i
                        style={{ color: "#ED6464", cursor: "pointer" }}
                        onClick={() => toggle(row.file)}
                        className="fas fa-trash-alt"
                    ></i>
                </div>
        },
    ]

    const { SearchBar } = Search

    //
    if (localStorage.getItem("anneeScolaire")) {
        var anneeScolaire = JSON.parse(
            localStorage.getItem("anneeScolaire")
        )
        var annee_id = anneeScolaire.Annee.id
    }
    useEffect(async () => {
        const res = await API.post("get_all_document",{
            annee_id,semestre_id:props.semestre.value
        }).then(res => {
            setOrders(res.data.Etudiant)
        })
        setLoading(true)
    }, [props.semestre.value])

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }
    const download = async (cin) => {
        const res = await API.post(
            "resultat/download_admin",
            {
                cin:cin,
                ann:annee_id,
                semestre_id:props.semestre.value
            },
            { responseType: "blob" }
        ).then(res => {
            FileDownload(res.data, `${cin + ".pdf"}`)
        })
    }
    const toggleDelete = async () => {
        const res = await API.post("delete_resultat", {
            cin: id,
            semestre_id:props.semestre.value,
            ann:annee_id,
        }).then(res => {
            API.post("get_all_document",{
                annee_id,semestre_id:props.semestre.value
            }).then(res => {
                setOrders(res.data.Etudiant)
            })
            setLoading(true)
        })
    }

    useEffect(() => {
        setModal(false)
    }, [orders])

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon" />
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication />}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary" />
                        </div>
                        <h4
                            style={{ textAlign: "center", marginTop: "2%" }}
                            className="ms-6"
                        >
                            {props.t("Chargement")}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{ width: "100% !important", textAlign: "center !important" }}
                    toggle={toggle}
                    tag="h4"
                >
                    {props.t("Suppression fichier ")}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>
                                {props.t("Êtes-Vous sûr de vouloir supprimer cette fichier ?")}

                            </p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    {props.t("Oui")}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    {props.t("Non")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(ListResultatEtudiant))
ListResultatEtudiant.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
    semestre: PropTypes.object,
    session: PropTypes.object,
}
