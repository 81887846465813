import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../api"
import { Button, Col, Spinner, Table } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import FileDownload from "js-file-download"

const ConsultMoyen = props => {
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)
  const [etat, setEtat] = useState(0)

  useEffect(async () => {
    const resE = await API.post("seance/absance/calcule_moyenne", {
      group_id: props.listGroup.id_group,
      matiere_id: props.matiere_id,
      type: props.type,
    })
    if (resE.data.status === 200) {
      setEtudiantList(resE.data.SeanceDate)
      setEtat(0)
      setLoading(true)
    } else {
      setEtat(1)
    }
  }, [props.listGroup.id_group])

  const upload = async () => {
    const res = await API.post(
      "seance/absance/export_moyenne",
      {
        group_id: props.listGroup.id_group,
        matiere_id: props.matiere_id,
        type: props.type,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(
        res.data,
        "Notes&Présence" + "(" + props.listGroup.label_group + ")" + ".xlsx"
      )
    })
  }

  return (
    <React.Fragment>
      {etat == 0 ? (
        <Fragment>
          {" "}
          {loading ? (
            <div>
              <Col>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn  mb-2 me-2"
                    onClick={upload}
                  >
                    <i className="fas fa-file-import me-1" />
                    Export
                  </Button>
                </div>
              </Col>
              <Table className="mt-3" striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>CIN</th>
                    <th style={{ textAlign: "center" }}>Etudiant</th>
                    <th style={{ textAlign: "center" }}>Moyenne</th>
                  </tr>
                </thead>
                <tbody>
                  {etudiantList.map((el, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}> {el.cin} </td>
                      <td style={{ textAlign: "center" }}> {el.etudiant} </td>
                      <td style={{ textAlign: "center" }}> {el.moyenne} </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Col lg="6">
                  <div className="text-center mt-4">
                    <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={() => props.history.push("/ListAbsence")}
                    >
                      Annuler
                    </Button>
                  </div>
                </Col>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                Chargement en cours...
              </h4>
            </div>
          )}
        </Fragment>
      ) : (
        <div>
          <h4 className="text-center mt-4">Aucune moyenne pour le moment 😉</h4>
        </div>
      )}
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(ConsultMoyen)
ConsultMoyen.propTypes = {
  listGroup: PropTypes.object,
  matiere_id: PropTypes.object,
  type: PropTypes.object,
  history: PropTypes.object,
}
