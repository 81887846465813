import React from "react"
import { Modal, ModalBody } from "reactstrap"
import PropTypes from "prop-types"

const ModalSurveillanceJour = ({ toggle, modal, row, detailExam,toggleClose }) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggleClose}
      style={{
        maxWidth: "1000px",
        width: "100%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
      fade={false}
    >
      <div
        className="modal-header"
        style={{
          width: "100% !important",
          justifyContent: "center !important",
          margin: "0 auto",
          fontSize: "17px",
          display: "flex",
        }}
        toggle={toggleClose}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingRight: "5px",
            color: "#556ee6",
          }}
        >
          Surveillance par jour
        </p>
        <i
          onClick={""}
          style={{ color: "#761C19", paddingRight: "5px", cursor: "pointer" }}
          className="fas fa-print"
        ></i>
      </div>
      <div
        className="mt-4"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "bold" }}>Date:</p>
          <p style={{ paddingLeft: "5px", color: "#556ee6" }}>{row.date}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "bold" }}>Session:</p>
          <p style={{ paddingLeft: "5px", color: "#556ee6" }}>Principale</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "bold" }}>Semestre:</p>
          <p style={{ paddingLeft: "5px", color: "#556ee6" }}>1</p>
        </div>
      </div>
      <ModalBody>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Horaire
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Jour
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Matiere
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Enseignant
              </th>
            </tr>
          </thead>
          {detailExam.map((el, index) => (
            <tbody key={index}>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "2px solid",
                    verticalAlign: "middle",
                  }}
                  rowSpan={el.detail.length + 1}
                >
                  {el.quart}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "2px solid",
                    verticalAlign: "middle",
                  }}
                  rowSpan={el.detail.length + 1}
                >
                  {el.jour}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "2px solid",
                    verticalAlign: "middle",
                  }}
                  rowSpan={el.detail.length + 1}
                >
                  {el.matiere}
                </td>
              </tr>
              {el.detail.map((elem, indice) => (
                <tr key={indice}>
                  <td style={{ textAlign: "center", border: "2px solid" }}>
                    {elem.enseignant}
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
        </table>
      </ModalBody>
    </Modal>
  )
}

export default ModalSurveillanceJour
ModalSurveillanceJour.propTypes = {
  modal: PropTypes.object,
  toggle: PropTypes.object,
  row: PropTypes.object,
  detailExam: PropTypes.object,
  toggleClose: PropTypes.object,
}
