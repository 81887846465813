import React, { useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import DataTableResultatExamen from "./DataTableResultatExamen";
import AddResultatExamen from "./AddResultatExamen";

const ResultatExamen = props => {
    const [section, setSection] = useState(1)

    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableResultatExamen />
        } else if (section === 2) {
            return <AddResultatExamen setSection={setSection} back={back} />
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Résultat d'examen")} | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    {props.t("Resultat Examen")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: section === 2,
                                    })}
                                    onClick={() => {
                                        setSection(2)
                                    }}
                                >
                                    {props.t("Ajouter Resultat Examen ")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(ResultatExamen))
ResultatExamen.propTypes = {
    t: PropTypes.any,
}
