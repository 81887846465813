import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Container,
  Card,
  CardBody,
  Spinner, Modal, ModalHeader, ModalBody,
} from "reactstrap"
import Select from "react-select"

const ViewGroupCour = props => {
  const [selectSemestre, setSelectSemestre] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [effectif, setEffectif] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [nbrTD, setNbrTD] = useState("")
  const [nbrTP, setNbrTP] = useState("")
  const [loading, setLoading] = useState(true)
  const [group, setGroup] = useState("");
  const [groupEdit, setGroupEdit] = useState("");
  const [arrayGroupe, setArrayGroupe] = useState([
    {
      identifiantGroupe: "",
      NomGroupe: "",
      TypeGroupe: "",
      capacite: "",
      nombreEtudiant: "",
    },
  ])
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("group/get_by_id", { cyc_niv_sem_spec_id :id }).then(res => {
      setSelectNiveau(res.data.Group.niveau)
      setSelectSemestre(res.data.Group.semestre)
      setSelectSpecialite(res.data.Group.specialite)
      setEffectif(res.data.Group.effectif)
      setNbrCours(res.data.Group.cours)
      setNbrTD(res.data.Group.td)
      setNbrTP(res.data.Group.tp)
      setArrayGroupe(res.data.Group.group_cours)
      setLoading(true)
    })
  }, [])
  const toggle = el => {
    setModal(!modal)
    setGroup(el)
  }
  const toggleEdit = el => {
    setModalEdit(!modalEdit)
    setGroupEdit(el)
  }
  const toggleDelete = async () => {
    if (localStorage.getItem("userAuthScolarite")) {
      var userAuthScolarite = JSON.parse(
          localStorage.getItem("userAuthScolarite")
      )
      var user_id = userAuthScolarite.user.id
    }
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("group/delete_by_id_cours", {
      id: group.id,
      semestre_id:selectSemestre.value,
      user_id
    }).then(res => {
      API.post("group/get_by_id", {cyc_niv_sem_spec_id: id }).then(res => {
        setSelectNiveau(res.data.Group.niveau)
        setSelectSemestre(res.data.Group.semestre)
        setSelectSpecialite(res.data.Group.specialite)
        setEffectif(res.data.Group.effectif)
        setNbrCours(res.data.Group.cours)
        setNbrTD(res.data.Group.td)
        setNbrTP(res.data.Group.tp)
        setArrayGroupe(res.data.Group.group_cours)
        setModal(!modal)
      })
    })
  }
  const toggleEditGroup = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    console.log(groupEdit)
    const res = await API.post("group/update_by_id_cours", {
      id: groupEdit.id,
      NomGroupe: groupEdit.NomGroupe,
      TypeGroupe: groupEdit.TypeGroupe,
      capacite: groupEdit.capacite,
      identifiantGroupe: groupEdit.identifiantGroupe,
      nombreEtudiant: groupEdit.nombreEtudiant,
    }).then(res => {
      API.post("group/get_by_id", {cyc_niv_sem_spec_id: id}).then(res => {
        setSelectNiveau(res.data.Group.niveau)
        setSelectSemestre(res.data.Group.semestre)
        setSelectSpecialite(res.data.Group.specialite)
        setEffectif(res.data.Group.effectif)
        setNbrCours(res.data.Group.cours)
        setNbrTD(res.data.Group.td)
        setNbrTP(res.data.Group.tp)
        setArrayGroupe(res.data.Group.group_cours)
        setModalEdit(!modalEdit)
      })
    })
  }
  const addIdentifiantGroupe = (event, index) => {
    let identifiantGroupe = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index
                ? Object.assign(el, {identifiantGroupe: identifiantGroupe})
                : el
        )
    )
  }
  const addNomGroupe = (event, index) => {
    let NomGroupe = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index ? Object.assign(el, {NomGroupe: NomGroupe}) : el
        )
    )
  }
  const addTypeGroupe = (event, index) => {
    let TypeGroupe = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index ? Object.assign(el, {TypeGroupe: TypeGroupe}) : el
        )
    )
  }
  const addCapacite = (event, index) => {
    let capacite = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index ? Object.assign(el, {capacite: capacite}) : el
        )
    )
  }
  const addNombreEtudiant = (event, index) => {
    let nombreEtudiant = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index
                ? Object.assign(el, { nombreEtudiant: nombreEtudiant })
                : el
        )
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Semestre:
                          </Label>
                          <Select
                            value={selectSemestre}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Niveau</Label>
                          <Select value={selectNiveau} isSearchable={true} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Spécialité
                          </Label>
                          <Select
                            value={selectSpecialite}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Effectif :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Effectif"
                            type="number"
                            value={effectif}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe cours :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe cours"
                            type="number"
                            value={nbrCours}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe TD :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe TD"
                            type="number"
                            value={nbrTD}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe TP :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe TP"
                            type="number"
                            value={nbrTP}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <CardTitle className="h4 mt-4">Groupe de cours</CardTitle>
                  <table className="table table-bordered mt-4">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Identifiant groupe
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Nom groupe
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Type groupe
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Capacité
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Nombre etudiant
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Modifier
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                        Supprimer
                      </th>
                      </tr>
                    </thead>
                    {arrayGroupe.map((el, index) => (
                        <tbody key={index}>
                        <tr>
                          <td className="col-md-2">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Identifiant Groupe"
                                onChange={e => addIdentifiantGroupe(e, index)}
                                value={el.identifiantGroupe}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Nom Groupe"
                                onChange={e => addNomGroupe(e, index)}
                                value={el.NomGroupe}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Type Groupe"
                                onChange={e => addTypeGroupe(e, index)}
                                value={el.TypeGroupe}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                                type="number"
                                className="form-control"
                                placeholder="Capacité"
                                onChange={e => addCapacite(e, index)}
                                value={el.capacite}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                                type="number"
                                className="form-control"
                                placeholder="Nombre Etudiant"
                                onChange={e => addNombreEtudiant(e, index)}
                                value={el.nombreEtudiant}
                            />
                          </td>
                          <td className="col-md-2">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <i style={{ color: "#c59f03", cursor: "pointer" }} onClick={() => toggleEdit(el)} className="fas fa-edit"></i>
                            </div>
                          </td>
                          <td className="col-md-2">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <i style={{ color: "#ED6464", cursor: "pointer" }} onClick={() => toggle(el)} className="fas fa-trash-alt"></i>
                            </div>
                          </td>

                        </tr>
                        </tbody>
                    ))}
                  </table>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Groupe")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" ,color:'red!important'}}
            toggle={toggle}
            tag="h4"
        >
          <h3 style={{color : "red"}}>NB : Cette action est irréversible. Si vous supprimez ce groupe, toutes ses relations seront automatiquement supprimées. (emploi du temps ,seances ,affectation des groupes pour enseignants ....)</h3>

          {"Suppression d'un groupe"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>

              <p>Voulez-vous supprimer ce groupe ?</p>

            </div>
            <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggleDelete}
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggle}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>

            </div>

          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalEdit} toggle={toggleEdit} centered={true}>
        <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" ,color:'red!important'}}
            toggle={toggleEdit}
            tag="h4"
        >
          {"Modification le nom du groupe"}
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>

              <p>Voulez-vous modifier ce groupe ?</p>

            </div>
            <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggleEditGroup}
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggleEdit}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>

            </div>

          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ViewGroupCour
ViewGroupCour.propTypes = {
  history: PropTypes.object,
}
