import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader, Label,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"

const AddMatiere = props => {
  const [arrayMatiere, setArrayMatiere] = useState([
    {
      departement_id: "",
      modalite: "",
      name: "",
      coef: "",
      credit: "",
      nbhCours: "",
      nbhTD: "",
      nbhTP: "",
      autre: "",
      OptionAutre: "",
      pourcentage_cour:"",
      pourcentage_td:"",
      pourcentage_tp:""
    },
  ])
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [modalite, setModalite] = useState([
    { value: 1, label: "Régime mixte" },
    { value: 2, label: "Contrôle continu" },
  ])
  const [departement, setDepartement] = useState([])
  const [optionAutre, setOptionAutre] = useState([
    { value: 1, label: "Cours Non Intégrée" },
    { value: 2, label: "Cours + TD" },
    { value: 3, label: "Cours + TP" },
  ])
  useEffect(async () => {
    // Département
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
  }, [])
  const addLigne = () => {
    let element = {
      name: "",
      coef: "",
      credit: "",
      nbhCours: "",
      nbhTD: "",
      nbhTP: "",
      autre: "",
      OptionAutre: "",
      modalite: "",
      departement_id: "",
    }
    setArrayMatiere([...arrayMatiere, element])
  }
  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }
  const toggleDelete = async () => {
    arrayMatiere.splice(indexLigne, 1)
    setModal(false)
  }
  const addMatiere = (event, index) => {
    let matiere = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { name: matiere }) : el
        )
    )
  }
  const addCoeff = (event, index) => {
    let coeff = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { coef: coeff }) : el
        )
    )
  }
  const addCredit = (event, index) => {
    let credit = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { credit: credit }) : el
        )
    )
  }
  const addNbhCours = (event, index) => {
    let nbhCours = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { nbhCours: nbhCours }) : el
        )
    )
  }
  const addNbhTD = (event, index) => {
    let nbhTD = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { nbhTD: nbhTD }) : el
        )
    )
  }
  const addNbhTP = (event, index) => {
    let nbhTP = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { nbhTP: nbhTP }) : el
        )
    )
  }
  const addPourcentageCours = (event, index) => {
    let pourcentage_cour = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { pourcentage_cour: pourcentage_cour }) : el
        )
    )
  }
  const addPourcentageTD = (event, index) => {
    let pourcentage_td = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { pourcentage_td: pourcentage_td }) : el
        )
    )
  }
  const addPourcentageTP = (event, index) => {
    let pourcentage_tp = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { pourcentage_tp: pourcentage_tp }) : el
        )
    )
  }
  const addNbhAutre = (event, index) => {
    let nbhAutre = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { autre: nbhAutre }) : el
        )
    )
  }
  const addModalite = (event, index) => {
    let modalite = event.label
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { modalite: modalite }) : el
        )
    )
  }
  const addOptionAutre = (event, index) => {
    let OptionAutre = event.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { OptionAutre: OptionAutre }) : el
        )
    )
  }
  const addDepartement = (event, index) => {
    let departement = event.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { departement_id: departement }) : el
        )
    )
  }
  const save = async () => {
    var etat = true
    for (let i = 0; i < arrayMatiere.length; i++) {
      var name = arrayMatiere[i].name
      var OptionAutre = arrayMatiere[i].OptionAutre
      var departement_id = arrayMatiere[i].departement_id
      var nbhCours = arrayMatiere[i].nbhCours
      var nbhTD = arrayMatiere[i].nbhTD
      var nbhTP = arrayMatiere[i].nbhTP
      var autre = arrayMatiere[i].autre
      var pourcentage_cour = arrayMatiere[i].pourcentage_cour
      var pourcentage_td = arrayMatiere[i].pourcentage_td
      var pourcentage_tp = arrayMatiere[i].pourcentage_tp
      if(nbhCours == ""){
        nbhCours=0;
        arrayMatiere[i].nbhCours=nbhCours
      }
      if(pourcentage_tp == ""){
        pourcentage_tp=0;
        arrayMatiere[i].pourcentage_tp=pourcentage_tp
      }
      if(pourcentage_td == ""){
        pourcentage_td=0;
        arrayMatiere[i].pourcentage_td=pourcentage_td
      }
      if(pourcentage_cour == ""){
        pourcentage_cour=0;
        arrayMatiere[i].pourcentage_cour=pourcentage_cour
      }
      if(nbhTD == ""){
        nbhTD=0;
        arrayMatiere[i].nbhTD=nbhTD
      }
      if(nbhTP == ""){
        nbhTP=0;
        arrayMatiere[i].nbhTP=nbhTP
      }
      if(autre == ""){
        autre=0;
        arrayMatiere[i].autre=autre
      }
      var tot=nbhCours+nbhTD+nbhTP+autre
      if ( name == ""){
        var etat = false
        toast.error("⛔ Saisir  le nom du matière", {
          containerId: "A",
        })
      }else if(OptionAutre == ""){
        var etat = false
        toast.error("⛔ Option cours intégrée est obligatoire ! ", {
          containerId: "A",
        })
      }else if(departement_id ==""){
        var etat = false
        toast.error("⛔ Département est obligatoire ! ", {
          containerId: "A",
        })
      }else  if((nbhCours == "" && nbhTD == "" && nbhTP=="" && autre=="") || (tot == 0)){
        var etat = false
        toast.error("⛔ Saisir au moins un nbh (cours , TD , TP ,Cours Intégrée)  ! ", {
          containerId: "A",
        })
      }
      if(OptionAutre == 1){
        OptionAutre="0";
        arrayMatiere[i].OptionAutre=OptionAutre
      }else if(OptionAutre == 2){
        OptionAutre =1;
        arrayMatiere[i].OptionAutre=OptionAutre
      }else  if(OptionAutre == 3){
        OptionAutre=2;
        arrayMatiere[i].OptionAutre=OptionAutre
      }

    }
    if (arrayMatiere.length == 0) {
      var etat = false
      toast.error("⛔ Saisir au moins une matière", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await API.post("matiere/add", {
        ligne: arrayMatiere,
      }).then(res => {
        props.setSection(1)
      })
    }
  }

  return (
    <React.Fragment>
      <CardTitle style={{ color: "#2a3042" }}  className="h4 mt-2">Ajouter matière</CardTitle>

      <Row>
        <Col lg="6">
          <div className="mb-3">
          </div>
        </Col>
      </Row>
      <table className="table table-bordered mt-4">
        <thead>
        <tr>
          <th style={{ textAlign: "center" }} scope="col">
            Département
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Modalité
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Matière
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Coeff
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Crédit
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Nbh cours
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Nbh TD
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Nbh TP
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Nbh cours intégrée
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Option cours intégrée
          </th>
          <th style={{ textAlign: "center" }} scope="col">
            Pourcentage Cours
          </th><th style={{ textAlign: "center" }} scope="col">
          Pourcentage TD
        </th><th style={{ textAlign: "center" }} scope="col">
          Pourcentage TP
        </th>
          <th style={{ textAlign: "center" }} scope="col">
            <i
                style={{
                  color: "#a0ceb8",
                  cursor: "pointer",
                }}
                className="fas fa-plus"
                onClick={addLigne}
            />
          </th>
        </tr>
        </thead>
        {arrayMatiere.map((el, index) => (
            <tbody key={index}>
            <tr>
              <td className="col-md-1">
                <Select
                    options={departement}
                    isSearchable={true}
                    onChange={e => addDepartement(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Select
                    options={modalite}
                    isSearchable={true}
                    onChange={e => addModalite(e, index)}
                />
              </td>
              <td className="col-md-2">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Matière ..."
                    onChange={e => addMatiere(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Coeff ..."
                    onChange={e => addCoeff(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Credit ..."
                    onChange={e => addCredit(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="nbh cours ..."
                    onChange={e => addNbhCours(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="nbh TD ..."
                    onChange={e => addNbhTD(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="nbh TP ..."
                    onChange={e => addNbhTP(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="nbh autre ..."
                    onChange={e => addNbhAutre(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Select
                    options={optionAutre}
                    isSearchable={true}
                    onChange={e => addOptionAutre(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Pourcentage Cours"
                    onChange={e => addPourcentageCours(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Pourcentage TD"
                    onChange={e => addPourcentageTD(e, index)}
                />
              </td>
              <td className="col-md-1">
                <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Pourcentage TP"
                    onChange={e => addPourcentageTP(e, index)}
                />
              </td>
              <td className="col-md-1" style={{ textAlign: "center" }}>
                <i
                    style={{ color: "#cc0000" }}
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => toggle(index)}
                />
              </td>
            </tr>
            </tbody>
        ))}
      </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {" Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddMatiere
AddMatiere.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
