import React, { useEffect, useState } from "react"
import { Button, Col, Form, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import Select from "react-select"

const AddSurveillance = props => {
  const [quart, setQuart] = useState([])
  const [selectQuart, setSelectQuart] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [date, setDate] = useState([])
  const [selectDate, setSelectDate] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")

  useEffect(async () => {
    // quartExamen
    const resExamen = await API.get("quart/examen/list").then(resExamen => {
      setQuart(resExamen.data.QuartExamen)
    })
    // quartExamen
    const resDate = await API.get("surveillance/get_date").then(resDate => {
      setDate(resDate.data.dates)
    })
    const res = await API.get("enseignant/select").then(res => {
      setEnseignant(res.data.select_enseignant)
    })
  }, [])

  const save = async () => {
    const res = await API.post("surveillance/add", {
      enseignant_id: selectEnseignant.value,
      quart_id: selectQuart.value,
      date: selectDate.label,
      semestre_id: selectSemestre.value,
    }).then(res => {
      props.setSection(1)
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Semestre</Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  value={selectSemestre}
                  onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Enseignant</Label>
                <Select
                  options={enseignant}
                  isSearchable={true}
                  isClearable={true}
                  onChange={e => setSelectEnseignant(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Date</Label>
                <Select
                  options={date}
                  isSearchable={true}
                  isClearable={true}
                  onChange={e => setSelectDate(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Heure Examen</Label>
                <Select
                  options={quart}
                  isSearchable={true}
                  isClearable={true}
                  onChange={e => setSelectQuart(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddSurveillance
AddSurveillance.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
