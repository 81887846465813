import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import ListDS2SemUn from "./ListDS2SemUn"

const NoteDS2SemUn = () => {
  const [niveau, setNiveau] = useState([
    { value: 1, label: "1ére Année", section: 0 },
    { value: 2, label: "2éme Année", section: 1 },
    { value: 3, label: "3éme Année", section: 2 },
  ])
  const [section, setSection] = useState("")

  const renderBlock = section => {
    for (let i = 0; i < niveau.length; i++) {
      if (section === i) {
        return <ListDS2SemUn niveau={niveau[i]} />
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Nav className="icon-tab nav-justified mt-3 mb-3">
            {niveau.map((element, index) => (
              <NavItem key={index}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === element.section,
                  })}
                  onClick={() => {
                    setSection(element.section)
                  }}
                >
                  <span className="d-none d-sm-block">{element.label}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>{" "}
          {renderBlock(section)}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(NoteDS2SemUn))
NoteDS2SemUn.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
