import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  ModalHeader, CardTitle, Form,
} from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"
import { Link } from "react-router-dom"
import DatePicker from "react-datepicker";
import getDay from "date-fns/getDay"
import "react-toastify/dist/ReactToastify.css"
const DataTableRattrapageStat = props => {
  const [loading, setLoading] = useState(false)

  // Data Table
  const [orders, setOrders] = useState([])
  const [jourId, setJourId] = useState("")
  const [quartId, setQuartId] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")


  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPage: 40,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "date_creation",
      text: props.t("Crée le"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "quart",
      text: props.t("Quart"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: props.t("Matiere"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type",
      text: props.t("Type"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: props.t("Salle"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    }
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resEnseignant = await API.get("enseignant/select").then(resEnseignant => {
      setEnseignant(resEnseignant.data.select_enseignant)
    })
    const res = await API.get("avis/rattrapage/list_historique").then(res => {
      setOrders(res.data.list)
      if (res.data.list.length != 0) {
        setJourId(res.data.list[0].jour_id)
        setQuartId(res.data.list[0].quart_id)
      }
    })
    setLoading(true)
  }, [])


  const search = async () => {
    setOrders([])
    if (dateDebut != "" ) {
      //date debut
      let DateNote_debut = dateDebut
      let month_debut = "" + (DateNote_debut.getMonth() + 1)
      let day_debut = "" + DateNote_debut.getDate()
      let year_debut = DateNote_debut.getFullYear()
      if (month_debut.length < 2) month_debut = "0" + month_debut
      if (day_debut.length < 2) day_debut = "0" + day_debut
      var convertDate_debut = [year_debut, month_debut, day_debut].join("-")
    }else{
      convertDate_debut="";
    }
    if ( dateFin!="") {
      ///date fin
      let DateNote_fin = dateFin
      let month_fin = "" + (DateNote_fin.getMonth() + 1)
      let day_fin = "" + DateNote_fin.getDate()
      let year_fin = DateNote_fin.getFullYear()
      if (month_fin.length < 2) month_fin = "0" + month_fin
      if (day_fin.length < 2) day_fin = "0" + day_fin
      var convertDate_fin = [year_fin, month_fin, day_fin].join("-")
    }else{
      convertDate_fin="";
    }
      const res = await API.post("avis/rattrapage/search_historique", {
        date_debut: convertDate_debut,
        date_fin: convertDate_fin,
        enseignant_id:selectEnseignant.value
      }).then(res => {
        setOrders(res.data.list)
      })
  }

  const searchAll = async () => {
    const res = await API.get("avis/rattrapage/list_historique").then(res => {
      setOrders(res.data.list)
      if (res.data.list.length != 0) {
        setJourId(res.data.list[0].jour_id)
        setQuartId(res.data.list[0].quart_id)
        setDateDebut("")
        setDateFin("")
        setSelectEnseignant("")
      }
    })
  }

  const [dateDebut, setDateDebut] = useState("")
  const [dateFin, setDateFin] = useState("")

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0
  }


  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">

          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Début :</Label>
                <DatePicker
                    selected={dateDebut}
                    filterDate={isWeekday}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy"
                    onChange={e => setDateDebut(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Fin :</Label>
                <DatePicker
                    selected={dateFin}
                    filterDate={isWeekday}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy"
                    onChange={e => setDateFin(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Enseignant :</Label>
                <Select
                    options={enseignant}
                    isSearchable={true}
                    isClearable={true}
                    value={selectEnseignant}
                    onChange={e => setSelectEnseignant(e)}
                />
              </div>
            </Col>

          </Row>
        </Form>
        <div
            className="mb-4"
            style={{ display: "flex", justifyContent: "space-around" }}
        >
          {" "}
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                  type="button"
                  style={{
                    backgroundColor: "#761C19",
                    borderColor: "#761C19",
                  }}
                  className="btn btn-success mb-2 me-2"
                  onClick={search}
              >
                Rechercher
              </button>
            </div>
          </Col>
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                  type="button"
                  style={{
                    backgroundColor: "#761C19",
                    borderColor: "#761C19",
                  }}
                  className="btn btn-success mb-2 me-2"
                  onClick={searchAll}
              >
                Afficher tout
              </button>
            </div>
          </Col>
        </div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>

      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableRattrapageStat))
DataTableRattrapageStat.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
