import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../api"
import Select from "react-select"
import { Row, Col, CardTitle, Label, Form, Button } from "reactstrap"
import { withRouter } from "react-router"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import FileDownload from "js-file-download"
import { Link } from "react-router-dom"

const ExportEnsiegnant = props => {
  const [grade, setGrade] = useState([])
  const [selectGrade, setSelectGrade] = useState("")
  const [active, setActive] = useState([
    { value: 0, label: "Non Actif" },
    { value: 1, label: "Actif" },
  ])
  const [selectActive, setSelectActive] = useState("")
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")

  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )

  const Columns = () => [
    {
      dataField: "code",
      text: "Numéro",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Name",
      text: "Nom et prénom",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "grade",
      text: "Grade",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Exploration",
      isDummyField: true,
      text: "Exploration",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/DetailEnseignants?id=" + row.id}>
            <i
              style={{ color: "darkcyan", cursor: "pointer" }}
              className="fas fa-eye"
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.get("enseignant/liste").then(res => {
      setOrders(res.data.enseignants)
    })
    const resG = await API.get("grade/select").then(resG => {
      setGrade(resG.data.Grade)
    })
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
  }, [])

  const search = async () => {
    if (selectGrade != "") {
      var grade_id = selectGrade.value
    } else {
      var grade_id = 0
    }
    if (selectDepartement != "") {
      var departement_id = selectDepartement.value
    } else {
      var departement_id = 0
    }
    if (selectActive != "") {
      var active = selectActive.value
    } else {
      var active = 2
    }
    const res = await API.post("enseignant/search", {
      grade_id: grade_id,
      departement_id: departement_id,
      active: active,
    }).then(res => {
      setOrders(res.data.enseignants)
    })
  }

  const searchAll = async () => {
    const res = await API.get("enseignant/liste").then(res => {
      setOrders(res.data.enseignants)
    })
  }

  const upload = async () => {
    if (selectGrade != "") {
      var grade_id = selectGrade.value
    } else {
      var grade_id = 0
    }
    if (selectActive != "") {
      var active = selectActive.value
    } else {
      var active = 2
    }
    if (selectDepartement != "") {
      var departement_id = selectDepartement.value
    } else {
      var departement_id = 0
    }
    const res = await API.post(
      "enseignant/export",
      {
        grade_id: grade_id,
        departement_id: departement_id,
        active: active,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "enseignants.xlsx")
    })
  }

  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">
                  Grade Enseignant :
                </Label>
                <Select
                  options={grade}
                  isClearable={true}
                  isSearchable={true}
                  onChange={e => setSelectGrade(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Etat Enseignant :</Label>
                <Select
                  options={active}
                  isClearable={true}
                  isSearchable={true}
                  onChange={e => setSelectActive(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Département : </Label>
                <Select
                  options={departement}
                  isSearchable={true}
                  onChange={e => setSelectDepartement(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div
          className="mb-4"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          {" "}
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={search}
              >
                Rechercher
              </button>
            </div>
          </Col>
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={searchAll}
              >
                Afficher Tout
              </button>
            </div>
          </Col>
        </div>
        <Row>
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={orders}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn  mb-2 me-2"
                          onClick={upload}
                        >
                          <i className="fas fa-file-export me-1" />
                          Export
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    pagination={paginationFactory(pageOptions)}
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(ExportEnsiegnant)
ExportEnsiegnant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
