import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardTitle, Input, Label, Form, Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import user1 from "../../assets/images/users/userImage.png";
import API from "../../api"
// Toastify
import {ToastContainer, toast, Slide} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const UserProfile = props => {

  const passVerif = event => {
    var password = event
    if(password != ""){
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var id = userAuthScolarite.user.id
      const res = API.post("password_verification", {
        id: id,
        password: password,
      }).then(res => {
        var etat = res.data.Enseignant.etat
        if (etat == 0) {
          setErrPass(0)
        } else {
          setErrPass(1)
        }
      })
    }
  }
  const [ancPass, setAncPass] = useState("")
  const [id, setId] = useState("")
  const [errPass, setErrPass] = useState(1)
  const [password, setPassword] = useState("")
  const [verifPassword, setVerifPassword] = useState("")

  const save = async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var id = userAuthScolarite.user.id
    if (password == verifPassword) {
      const res = await API.post("reset_password", {
        password: password,
        id: id,
      })
      if (res.data.status === 200) {

        toast.success("Mot de passe a été modifié", {
          containerId: "A",

        });
        props.history.push("/dashboard");
      } else {
        toast.error("⛔ Token expiré", {
          containerId: "A",
        })
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Cresus </title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img
                        src={user1}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>

                <AvForm>
                  <Form>
                <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
                  Mise a jour password :
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label>Password actuel</Label>
                      <Input
                          lg="3"
                          className="form-control"
                          type="password"
                          onBlur={e => passVerif(e.target.value)}
                          onChange={e => setAncPass(e.target.value)}
                      />
                      {errPass == 0 ? (
                          <small className="form-text text-danger">
                            {"Password erroné"}
                          </small>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label>Nouveau password</Label>
                      <AvField
                          name="password"
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label>Verification password</Label>
                      <AvField
                          name="password1"
                          type="password"
                          value={verifPassword}
                          onChange={e => setVerifPassword(e.target.value)}
                          errorMessage="Verifier le password"
                          validate={{
                            match: { value: "password" },
                          }}
                      />
                    </div>
                  </Col>
                </Row>
                  </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >

                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                            className="text-center mt-4"
                        >
                          <button
                              type="submit"
                              className="btn btn-primary "
                              onClick={save}
                          >
                            Confirmer
                          </button>
                        </div>
                      </Col>
                    </div>


              </AvForm>
              <ToastContainer
                  transition={Slide}
                  enableMultiContainer
                  containerId={"A"}
                  position={toast.POSITION.TOP_RIGHT}
                  autoClose={2500}
              />
            </CardBody>
          </Card>
        </Container>


      </div>
    </React.Fragment>
  )
}

// export default withRouter(UserProfile)

export default UserProfile
UserProfile.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
