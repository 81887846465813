import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Container,
  Card,
  Input,
  Progress,
  CardBody,
  ModalHeader,
  Label,
} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ToastContainer, toast, Slide } from "react-toastify"

const ListMatieresDS2 = props => {
  const [modalDeleteFinale, setModalDeleteFinale] = useState(false);

  const cycle_id = props.location.state.element.cycle_id
  const niveau_id = props.location.state.element.niveau_id
  const specialite_id = props.location.state.element.specialite_id
  const session_id = props.location.state.element.session_id
  const semestre_id=props.location.state.element.semestre_id
  // console.log(semestre_id)
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [file, setFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [modal, setModal] = useState(false)
  const [examenId, setExamenId] = useState("")
  const [modalDelete, setModalDelete] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [date, setDate] = useState("")
  const [quart, setQuart] = useState([])
  const [selectQuart, setSelectQuart] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: props.t("Matière"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "quart_examen",
      text: props.t("Heure"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: props.t("Enseignant Responsable"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Import",
      isDummyField: true,
      text: props.t("Import"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat == 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#269d50", cursor: "pointer" }}
              onClick={() => toggle(row.id)}
              className="fas fa-file-import"

            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-file-import"
            ></i>
          </div>
        ),
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: props.t("Action hermargement"),
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <div className="text-success">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => toggleEdit(row.id)}
            ></i>
          </div>
          <div className="text-danger">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => toggleDelete(row.id)}
            ></i>
          </div>
        </div>
      ),
    },
    {
      dataField: "Action ",
      isDummyField: true,
      text: props.t("Action"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }} className="d-flex gap-3">
            <div className="text-danger">
              <i
                  style={{ color:"#fdd679", cursor: "pointer" }}
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => toggleDeleteFinale(row.id)}
              ></i>
            </div>
          </div>
      ),
    }
  ]
  const toggleDeleteFinale = id => {
    setModalDeleteFinale(!modalDeleteFinale)
    setExamenId(id)
  }

  const toggDeleteFinale = async () => {
    const res = await API.post("ds2/delete_all", {
      ds2_id: examenId,
    }).then(res => {
      const resE =  API.post("ds2/list_matiere", {
        niveau_id: niveau_id,
        cycle_id: cycle_id,
        specialite_id: specialite_id,
        session_id: session_id,
        semestre_id: semestre_id,
      }).then(resE => {
        setOrders(resE.data.ListMatieres)
        setLoading(true)
      })
    })
  }
  const { SearchBar } = Search

  useEffect(async () => {
    // quartExamen
    const resExamen = await API.get("quart/examen/list").then(resExamen => {
      setQuart(resExamen.data.QuartExamen)
    })
    const resE = await API.post("ds2/list_matiere", {
      niveau_id: niveau_id,
      cycle_id: cycle_id,
      specialite_id: specialite_id,
      session_id: session_id,
      semestre_id: semestre_id,
    }).then(resE => {
      setOrders(resE.data.ListMatieres)
      setLoading(true)
    })
    // enseignant
    const resEns = await API.get("enseignant/select").then(resEns => {
      setEnseignant(resEns.data.select_enseignant)
    })
  }, [])

  const toggle = id => {
    setModal(!modal)
    setExamenId(id)
    setUploadPercent(0)
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const uploadFile = async () => {
    let data = new FormData()
    data.append("file", file)
    data.append("examen_id", examenId)
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        if (percent < 100) {
          setUploadPercent(percent)
        }
      },
    }
    const result = API.post("ds2/import", data, options).then(
      result => {
        if (result.data.status == 300) {
          toast.error(
            result.data.msg + ". Vérifier les lignes" + result.data.Cin.ligne,
            {
              containerId: "A",
            }
          )
        } else if (result.data.status == 301) {
          toast.error(
            result.data.msg + ". Vérifier la ligne" + result.data.Cin,
            {
              containerId: "A",
            }
          )
        } else if (result.data.status == 302) {
          toast.error(
            result.data.msg + ". Vérifier la ligne" + result.data.Cin.ligne,
            {
              containerId: "A",
            }
          )
        } else {
          setUploadPercent(100)
          setModal(false)
          const resE = API.post("ds2/list_matiere", {
            niveau_id: niveau_id,
            cycle_id: cycle_id,
            specialite_id: specialite_id,
            session_id: session_id,
            semestre_id: semestre_id,
          }).then(resE => {
            setOrders(resE.data.ListMatieres)
            setLoading(true)
          })
        }
      }
    )
  }

  const toggleDelete = id => {
    setModalDelete(!modalDelete)
    setExamenId(id)
  }

  const toggDelete = async () => {
    const res = await API.post("ds2/delete_list", {
      examen_id: examenId,
    }).then(res => {
      const resE = API.post("ds2/list_matiere", {
        niveau_id: niveau_id,
        cycle_id: cycle_id,
        specialite_id: specialite_id,
        session_id: session_id,
        semestre_id: semestre_id,
      }).then(resE => {
        setOrders(resE.data.ListMatieres)
        setLoading(true)
      })
    })
  }

  useEffect(() => {
    setModalDelete(false)
    setModalDeleteFinale(false)

  }, [orders])

  const toggleEdit = id => {
    setModalEdit(!modalEdit)
    setSelectEnseignant("")
    setSelectQuart("")
    setDate("")
    setExamenId(id)
  }

  const edit = async () => {
    if (date != "") {
      let convert = date
      let month = "" + (convert.getMonth() + 1)
      let day = "" + convert.getDate()
      let year = convert.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    }
    const res = await API.post("ds2/edit", {
      examen_id: examenId,
      date: convertDate,
      quart_examen_id: selectQuart.value,
      enseignant_id: selectEnseignant,
    }).then(res => {
      setModalEdit(false)
      const resE = API.post("ds2/list_matiere", {
        niveau_id: niveau_id,
        cycle_id: cycle_id,
        specialite_id: specialite_id,
        session_id: session_id,
        semestre_id: semestre_id,
      }).then(resE => {
        setOrders(resE.data.ListMatieres)
        setLoading(true)
      })
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div>
                {loading ? (
                  <Row>
                    <Col xs="12">
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={Columns()}
                        search
                        bootstrap4
                      >
                        {toolkitProps => (
                          <div>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {props.t("Chargement")}
                    </h4>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={modalDeleteFinale} toggle={toggleDeleteFinale} centered={true}>
        <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggleDelete}
            tag="h4"
        >
          {props.t("Suppression définitive de DS2")}
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{props.t("Voulez-vous supprimer ce DS2 ?")}</p>
            </div>
            <div
                className="hvr-push mt-4"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggDeleteFinale}
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggleDeleteFinale}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "20px",
            color: "#556ee6",
          }}
          toggle={toggle}
        >
          Importation liste émergement
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            {uploadPercent <= 0 ? (
              <Input
                onChange={e => addFile(e)}
                className="form-control mt-4"
                type="file"
                id="formFile"
              />
            ) : null}
            {uploadPercent > 0 && uploadPercent < 100 ? (
              <Progress
                striped
                className="progress-xl"
                color="success"
                value={uploadPercent}
              >
                {uploadPercent}%
              </Progress>
            ) : null}
            {uploadPercent == 100 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                Success
                <i
                  style={{
                    paddingLeft: "5px",
                    color: "darkcyan",
                    paddingTop: "3.5px",
                  }}
                  className="fas fa-check me-1"
                />
              </div>
            ) : null}
            {uploadPercent != 100 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="button"
                  color="success"
                  className="btn mb-2 me-2 mt-4"
                  onClick={uploadFile}
                >
                  <i className="fas fa-cloud-upload-alt me-1" />
                  Upload
                </Button>
              </div>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggleDelete}
          tag="h4"
        >
          {props.t("Suppression liste hermargement")}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{props.t("Voulez-vous supprimer cette liste ?")}</p>
            </div>
            <div
              className="hvr-push mt-4"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDelete}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          maxWidth: "700px",
          width: "100%",
        }}
        isOpen={modalEdit}
        toggle={toggleEdit}
        centered={true}
      >
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
          }}
          toggle={toggleEdit}
          tag="h4"
        >
          Modification
        </ModalHeader>
        <ModalBody>
          <Fragment>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Date")} :
                  </Label>
                  <DatePicker
                    selected={date}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy"
                    onChange={e => setDate(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Heure Examen")}
                  </Label>
                  <Select
                    options={quart}
                    isSearchable={true}
                    isClearable={true}
                    onChange={e => setSelectQuart(e)}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Enseignants responsable")}
                  </Label>
                  <Select
                    options={enseignant}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={true}
                    onChange={e => setSelectEnseignant(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Col lg="6">
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={edit}
                    >
                      CONFIRMER
                    </button>
                  </div>
                </Col>
              </div>
            </Row>
          </Fragment>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ListMatieresDS2))
ListMatieresDS2.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.object,
}
