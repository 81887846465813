import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
  Spinner,
} from "reactstrap"
import API from "../../api"

const DetailEtudiant = props => {
  const [id, setId] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [nationnalite, setNationnalite] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [gender, setGender] = useState("")
  const [groupe, setGroupe] = useState([])
  const [date, setDate] = useState()
  const [fatherPhone, setFatherPhone] = useState()
  const [fatherName, setFatherName] = useState()
  const [civilState, setCivilState] = useState()
  const [addressStreet, setAddressStreet] = useState()
  const [addressZipCode, setAddressZipCode] = useState()
  const [addressState, setAddressState] = useState()
  const [addressCountry, setAddressCountry] = useState()
  const [bacSection, setBacSection] = useState()
  const [bacMention, setBacMention] = useState()
  const [bacSession, setBacSession] = useState()
  const [bacMedium, setBacMedium] = useState()
  const [bacYear, setBacYear] = useState()
  const [bacCountry, setBacCountry] = useState()
  const [active, setActive] = useState()
  const [photo, setPhoto] = useState("")
  const [loading, setLoading] = useState(false)
  //const [file, setFile] = useState(null)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("etudiant/get_by_id", {
      id: id,
    }).then(res => {
      setNom(res.data.etudiant.firstName)
      setPrenom(res.data.etudiant.lastName)
      setNumero(res.data.etudiant.code)
      setGender(res.data.etudiant.gender)
      setNationnalite(res.data.etudiant.nationality.label)
      setDate(res.data.etudiant.birthdate)
      setLieuNaissance(res.data.etudiant.birthplace)
      setAddressStreet(res.data.etudiant.addressStreet)
      setAddressZipCode(res.data.etudiant.addressZipCode)
      setAddressCountry(res.data.etudiant.addressCountry)
      setAddressState(res.data.etudiant.addressState)
      setEmail(res.data.etudiant.email)
      setTelephone(res.data.etudiant.telephone)
      setFatherPhone(res.data.etudiant.fatherPhone)
      setFatherName(res.data.etudiant.fatherName)
      setCivilState(res.data.etudiant.civilState)
      setBacSection(res.data.etudiant.bacSection)
      setBacMention(res.data.etudiant.bacMention)
      setBacSession(res.data.etudiant.bacSession)
      setBacMedium(res.data.etudiant.bacMedium)
      setBacYear(res.data.etudiant.bacYear)
      setBacCountry(res.data.etudiant.bacCountry)
      setGroupe(res.data.etudiant.group.label)
      setActive(res.data.etudiant.active)
      setId(res.data.etudiant.id)
      setPhoto(res.data.etudiant.photo)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consultation Etudiant | Cresus </title>
        </MetaTags>
        {loading ? (
          <Container fluid={true}>
            <Row>
              <Col className="pt-4" xl="6">
                <Card className="overflow-hidden">
                  <CardBody className="pt-4">
                    <Row>
                      <Col sm="4">
                        <div
                          style={{ marginTop: "5%" }}
                          className="avatar-md profile-user-wid mb-4"
                        >
                          <img
                            src={photo}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                      </Col>
                      <Col sm="8">
                        <div>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <p className="text-muted mb-0"> </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-home mb-2 me-2"></i>
                              <p className="text-muted mb-0">
                                {addressCountry}
                              </p>
                              <i
                                style={{ marginLeft: "1vw" }}
                                className="fas fa-city	 mb-2 me-2"
                              ></i>
                              <p className="text-muted mb-0">{addressState}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-home mb-2 me-2"></i>
                              <p className="text-muted mb-0">{addressStreet}</p>
                              <i
                                style={{ marginLeft: "1vw" }}
                                className="fas fa-address-card mb-2 me-2"
                              ></i>
                              <p className="text-muted mb-0">
                                {addressZipCode}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-phone mb-2 me-2"></i>
                              <p className="text-muted mb-0">{telephone}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-at mb-2 me-2"></i>
                              <p className="text-muted mb-0">{email}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Nom
                            </td>
                            <td>{nom}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Prénom
                            </td>
                            <td>{prenom}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-calendar-alt -bill mb-2 me-2"></i>
                              Date de Naissance
                            </td>
                            <td>{date}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-map-marker -bill mb-2 me-2"></i>
                              Lieu de Naissance
                            </td>
                            <td>{lieuNaissance}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Nom Pére
                            </td>
                            <td>{fatherName}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          {gender === 1 ? (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-male -bill mb-2 me-2"></i>
                                Sexe
                              </td>
                              <td> Masculin</td>
                            </tr>
                          ) : (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-female -bill mb-2 me-2"></i>
                                Sexe
                              </td>
                              <td>Féminin</td>
                            </tr>
                          )}
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-flag -bill mb-2 me-2"></i>
                              Nationnalité
                            </td>
                            <td>{nationnalite}</td>
                          </tr>
                          {civilState === 1 ? (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                                État Civil
                              </td>
                              <td> Célibataire </td>
                            </tr>
                          ) : civilState === 2 ? (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                                État Civil
                              </td>
                              <td> Marié </td>
                            </tr>
                          ) : (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                                État Civil
                              </td>
                              <td> Divorcé </td>
                            </tr>
                          )}
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                              Numéro
                            </td>
                            <td>{numero}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                              Numéro Pére
                            </td>
                            <td>{fatherPhone}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-chalkboard-teacher mb-2 me-2"></i>
                              Section Bac
                            </td>
                            <td>{bacSection}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-calendar-check -bill mb-2 me-2"></i>
                              Baccalauréat Année
                            </td>
                            <td>{bacYear}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-check-square mb-2 me-2"></i>
                              Moyenne De Bac
                            </td>
                            <td>{bacMedium}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-users -bill mb-2 me-2"></i>
                              Groupe
                            </td>
                            <td>{groupe}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-map-marker -bill mb-2 me-2"></i>
                              Bac Pays
                            </td>
                            <td>{bacCountry}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-book-reader -bill mb-2 me-2"></i>
                              Session Bac
                            </td>
                            <td>{bacSession}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-check-square  mb-2 me-2"></i>
                              Mention Bac
                            </td>
                            <td>{bacMention}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "felx-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="success"
                          className="btn btn-success mb-2 me-2"
                          onClick={() =>
                            props.history.push("/EditEtudiant?id=" + id)
                          }
                        >
                          Modifier
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <Link to="/Etudiant">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning mb-2 me-2 "
                          >
                            Annuler
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default DetailEtudiant

DetailEtudiant.propTypes = {
  history: PropTypes.object,
}
