import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Spinner,
  Table,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const AddNoteDS = props => {
  if (localStorage.getItem("userAuthScolarite")) {
    var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
    )
    var user_id = userAuthScolarite.user.id
  }
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)
  const group = props.location.state.group
  const matiere = props.location.state.matiere
  const group_id = props.location.state.group_id
  const matiere_id = props.location.state.matiere_id
  const type_examen_id = props.location.state.type_examen_id
  const date = props.location.state.date
  const semestre_id = props.location.state.semestre_id

  useEffect(async () => {
    const resE = await API.post("examen/passer/etudiants", {
      group_id: group_id,
      type_examen_id: type_examen_id,
      matiere_id: matiere_id,
      date: date,
      semestre_id: semestre_id,
    }).then(resE => {
      setEtudiantList(resE.data.Note)
      setLoading(true)
    })
  }, [])

  const changeNote = (event, index) => {
    if (/^[0-9,.]+$/.test(event)) {
      var note = event
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: note }) : el
        )
      )
    } else if (note == "") {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: "" }) : el
        )
      )
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      })
    }
  }

  const save = async () => {
    setLoading(false)
    const res = await API.post("scolarite/examen/passer/note/edit", {
      user_id: user_id,
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      semestre_id: semestre_id,
      date: date,
      etudiants: etudiantList,
    }).then(res => {
      props.history.push("/NoteDS")
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Card>
              <CardBody>
                <div className="mt-2">
                  <h5 style={{ color: "red" }}>
                    {" "}
                    NB : Cette action est irréversible
                  </h5>
                  <p>
                    {
                      "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                    }
                  </p>
                </div>
                <div className="d-flex">
                  <CardTitle className="h4 mt-4">Groupe :</CardTitle>
                  <CardTitle
                    style={{ paddingLeft: "5px", color: "#556ee6" }}
                    className="h4 mt-4"
                  >
                    {group}
                  </CardTitle>
                </div>
                <div className="d-flex">
                  <CardTitle className="h4 mb-4">Matière :</CardTitle>
                  <CardTitle
                    style={{ paddingLeft: "5px", color: "#556ee6" }}
                    className="h4 mb-4"
                  >
                    {matiere}
                  </CardTitle>
                </div>
                <div>
                  <Table className="mt-3" striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>CIN</th>
                        <th style={{ textAlign: "center" }}>Etudiant</th>
                        <th style={{ textAlign: "center" }}>Présence</th>
                        <th style={{ textAlign: "center" }}>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {etudiantList.map((el, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}> {el.cin} </td>
                          <td style={{ textAlign: "center" }}> {el.nom} </td>
                          <td
                            className="square-switch"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="checkbox"
                              id={"square-switch" + index}
                              switch="bool"
                              checked={el.isPresent}
                              disabled
                            />
                            <label
                              htmlFor={"square-switch" + index}
                              data-on-label="P"
                              data-off-label="A"
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="text"
                              onChange={e => changeNote(e.target.value, index)}
                              value={el.note != null ? el.note : ""}
                              style={{ textAlign: "center" }}
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="mt-2">
                    <h5 style={{ color: "red" }}>
                      {" "}
                      NB : Cette action est irréversible
                    </h5>
                    <p>
                      {
                        "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                      }
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/NoteDS")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={save}
                        >
                          Confirmer
                        </button>
                      </div>
                    </Col>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              Chargement en cours...
            </h4>
          </div>
        )}
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddNoteDS)
AddNoteDS.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
