import React, { useState } from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import PremiereAnneeSemestreDeux from "./PremiereAnneeSemestreDeux"
import DeuxiemeAnneeSemestreDeux from "./DeuxiemeAnneeSemestreDeux"
import TroisiemeAnneeSemestreDeux from "./TroisiemeAnneeSemestreDeux"

const DataTableValidationSemestre2 = props => {
  const [activeTab, setactiveTab] = useState("1")
  var url = window.location.href
  var array = url.split("=")
  var enseignant_id = array[1]
  return (
    <React.Fragment>
      <Nav className="icon-tab nav-justified">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              setactiveTab("1")
            }}
          >
            <span className="d-none d-sm-block">1 ére année</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              setactiveTab("2")
            }}
          >
            <span className="d-none d-sm-block">2 éme année</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              setactiveTab("3")
            }}
          >
            <span className="d-none d-sm-block">3 éme année</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 text-muted">
        <TabPane tabId="1">
          <PremiereAnneeSemestreDeux id={enseignant_id} />
        </TabPane>
        <TabPane tabId="2">
          <DeuxiemeAnneeSemestreDeux id={enseignant_id}  />
        </TabPane>
        <TabPane tabId="3">
          <TroisiemeAnneeSemestreDeux id={enseignant_id} />
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default withTranslation()(DataTableValidationSemestre2)
DataTableValidationSemestre2.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
