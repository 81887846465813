import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, CardTitle, Form, Label} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import DatePicker from "react-datepicker";
import Select from "react-select";
import FileDownload from "js-file-download";
import axios from "axios";

const DataTableEns = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [departement, setDepartement] = useState([]);
  const [selectDepartement, setSelectDepartement] = useState("");
  useEffect(async () => {

    const resDepartement = await API.get("departement/select").then(resDepartement => {
      setDepartement(resDepartement.data.Departement)
      setSelectDepartement("")
    })
    const res = await API.post("emploi/enseignant/list", {
      semestre_id: props.semestre,
    }).then(res => {
      setOrders(res.data.enseignants)
      setLoading(true)
    })
  }, [props.semestre])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.Annee.id
  const handleDownloadClick = async row => {

//     const pdfUrl =
//         "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
//         row.id +
//         "/" +
//         row.semestre;
//
// // Créez un lien invisible
//     const link = document.createElement("a");
//     link.href = pdfUrl;
//     link.target = "_blank";
//     link.style.display = "none";
//
// // Créez un en-tête 'annee' et ajoutez-le à la demande
//     const headers = new Headers();
//     headers.append("annee", annee);
//
// // Créez une demande avec l'en-tête 'annee'
//     const request = new Request(link.href, {
//       method: "GET",
//       headers: headers,
//     });
//
//     document.body.appendChild(link);
//
// // Effectuez la demande avec l'en-tête 'annee'
//     fetch(request)
//         .then(() => {
//           // Simulez un clic sur le lien pour ouvrir le PDF dans une nouvelle fenêtre
//           link.click();
//         })
//         .catch((error) => {
//           console.error("Erreur lors de la récupération du PDF :", error);
//         });
//
// // Supprimez le lien après l'ouverture
//     document.body.removeChild(link);




    // const pdfUrl =
    //     "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
    //     row.id +
    //     "/" +
    //     row.semestre;
    //
    // const headers = new Headers();
    // headers.append("annee", annee); // Remplacez VOTRE_TOKEN par votre jeton d'authentification
    //
    // fetch(pdfUrl, {
    //   method: "GET",
    //   headers: headers,
    // })
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error(`Erreur HTTP! Statut: ${response.status}`);
    //       }
    //       return response.blob();
    //     })
    //     .then((blob) => {
    //       const objectURL = URL.createObjectURL(blob);
    //
    //       // Ouvrir le PDF dans une nouvelle fenêtre
    //       const newWindow = window.open(objectURL, "_blank");
    //       if (!newWindow) {
    //         console.error("Impossible d'ouvrir une nouvelle fenêtre.");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Erreur lors de la récupération du PDF :", error);
    //     });




    API.post(`/impression_enseignant_shedules/${row.id}/${row.semestre}`,{} ,{
      headers: {
        'X-Frame-Options': 'DENY',
        'annee': annee, // Assurez-vous que 'annee' contient la valeur souhaitée
        // 'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    })
        .then((response) => {
          alert("vv")
          // Gérez la réponse ici
          if (response.status === 200) {
            // Ouvrir le PDF dans une nouvelle fenêtre
            window.open(response.data, '_blank');
          } else {
            // Gérer les autres cas de réponse ici
          }
        })
        .catch((error) => {
          // Gérez les erreurs ici
        });

    // axios.get(`https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}`, {
    //   headers: {
    //     'X-Frame-Options' : "DENY",
    //     'annee': annee,
    //     'Content-Type': 'application/json',
    //   },
    // })

    // const headers = {
    //   "annee": 4,
    // };
    //
    // const res = await API.get(`https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}`, {
    //
    // }).then((response) => {
    //  alert("ccc")
    // })
    // const url =
    //     "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
    //     row.id +
    //     "/" +
    //     row.semestre;
    //
    // const headers = new Headers();
    // headers.append("annee", annee);
    // headers.append("Access-Control-Allow-Origin", "*");
    // headers.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    //
    // fetch(url, {
    //   method: "GET",
    //   headers: headers,
    // })
    //     .then((response) => {
    //       if (response.ok) {
    //         return response.blob();
    //       } else {
    //         throw new Error("La requête a échoué.");
    //       }
    //     })
    //     .then((blob) => {
    //       const objectURL = URL.createObjectURL(blob);
    //       window.open(objectURL, "_blank"); // Ouvrez l'URL de l'objet blob dans une nouvelle fenêtre
    //     })
    //     .catch((error) => {
    //       console.error("Erreur de requête:", error);
    //     });
    //
    //
    // const url =
    //     "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
    //     row.id +
    //     "/" +
    //     row.semestre;
    // const headers = new Headers();
    // headers.append("annee", annee); // Remplacez VOTRE_TOKEN par votre jeton d'authentification
    // headers.append("X-Frame-Options", "DENY"); // Remplacez VOTRE_TOKEN par votre jeton d'authentification
    // headers.append("Content-Type", "application/json"); // Remplacez VOTRE_TOKEN par votre jeton d'authentification
    //
    // fetch(url, {
    //   method: "GET",
    //   headers: headers,
    // }).then((blob) => {
    //       const objectURL = URL.createObjectURL(blob);
    //       window.open(blob, '_blank');
    //     })
    // Construire l'URL avec les paramètres
    // const url = `https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}`;
    //
    // // Configurer les en-têtes de la requête
    // const headers = {
    //   'annee': annee,
    // };
    //
    // axios.get(url, { headers })
    //     .then(response => {
    //       window.open(response.data.url, '_blank');
    //     })
    //     .catch(error => {
    //       // Gérez les erreurs ici
    //     });

    // window.location.href = `https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}?annee=${annee}`;
    // try {
    //   const response = await axios.get(
    //       `https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}`,
    //       {
    //         headers: {
    //           Authorization: "Bearer VOTRE_TOKEN", // Ajoutez votre jeton d'authentification ici
    //         },
    //         responseType: "blob",
    //         withCredentials: true,
    //       }
    //   );
    //
    //   if (response.status === 200) {
    //     const blob = new Blob([response.data], { type: "application/pdf" });
    //     const objectURL = URL.createObjectURL(blob);
    //
    //     // Ouvrir le PDF dans une nouvelle fenêtre
    //     window.open(objectURL, "_blank");
    //
    //     // Il est recommandé de révoquer l'URL d'objet après utilisation pour libérer des ressources
    //     URL.revokeObjectURL(objectURL);
    //   } else {
    //     console.error(`Erreur HTTP! Statut: ${response.status}`);
    //   }
    // } catch (error) {
    //   console.error("Erreur lors de la récupération du PDF:", error);
    // }
    // const url = `https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}`;
    // const headers = {
    //   annee: annee,
    //   'content-type':"application/pdf",
    //   "Access-Control-Allow-Origin": "*"
    // };
    //
    // fetch(url, {
    //   method: "GET",
    //   headers: headers,
    // })
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error(`Erreur HTTP! Statut: ${response.status}`);
    //       }
    //       return response.blob();
    //     })
    //     .then((blob) => {
    //       const objectURL = URL.createObjectURL(blob);
    //
    //       // Ouvrir le PDF dans une nouvelle fenêtre
    //       window.open(objectURL, "_blank");
    //
    //       // Il est recommandé de révoquer l'URL d'objet après utilisation pour libérer des ressources
    //       URL.revokeObjectURL(objectURL);
    //     })
    //     .catch((error) => {
    //       console.error("Erreur lors de la récupération du PDF:", error);
    //     });


    // axios.get(url, { headers })
    //     .then((response) => {
    //       // Gérez la réponse ici, par exemple, ouvrez un nouvel onglet avec les données
    //       window.open(response.data.url, '_blank');
    //     })
    //     .catch((error) => {
    //       // Gérez les erreurs ici
    //       console.error(error);
    //     });

  }
  // const headers = {
  //   "annee": annee,
  // };
  const Columns = () => [
    {
      dataField: "name",
      text: "Enseignant",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "grade",
      text: "Grade",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Consultation",
      isDummyField: true,
      text: "Consultation",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{
              color: row.emploi == 0 ? "cornflowerblue" : "green",
              cursor: "pointer",
            }}
            onClick={() =>
              props.history.push(
                "/EmploiEnseignant?id=" + row.id + "/" + row.semestre
              )
            }
            className="fas fa-eye"
          ></i>
        </div>
      ),
    },
    {
      dataField: "Emploi",
      isDummyField: true,
      text: "Emploi",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{
              color: row.emploi == 0 ? "cornflowerblue" : "green",
              cursor: "pointer",
            }}
            onClick={() =>
              props.history.push(
                "/AddScheduleEnseignant?id=" + row.id + "/" + row.semestre
              )
            }
            className="fas fa-calendar-alt"
          ></i>
        </div>
      ),
    },
    {
      dataField: "id",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        // <a
        //
        //   href={
        //     "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
        //     row.id +
        //     "/" +
        //     row.semestre
        //   }
        //   target="_blank"
        //   rel="noreferrer"
        //   headers={headers}
        //   style={{ display: "flex", justifyContent: "center" }}
        // >
          <a
              href={
                  "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +row.id +"/" + row.semestre+"/"+annee }
              target="_blank"
              rel="noreferrer"
              style={{ display: "flex", justifyContent: "center" }}
              // onClick={(e) => {
              //   e.preventDefault(); // Empêche le lien de se comporter normalement
              // //   const url =
              // //       "https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
              // //       row.id +
              // //       "/" +
              // //       row.semestre;
              // //   const headers = {
              // //     // Définissez ici vos en-têtes HTTP personnalisés
              // //     "annee":annee,
              // //     "Access-Control-Allow-Origin": "*",
              // //   "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              // //   "Access-Control-Allow-Headers":" Content-Type"
              // //
              // //
              // // };
              //   axios.get(`https://scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/${row.id}/${row.semestre}`, {
              //  headers: {
              //       'X-Frame-Options' : "DENY",
              //       'annee': annee,
              //       'Content-Type': 'application/json',
              //    'Access-Control-Allow-Origin':'http://localhost:3000/SchedulesEnseignants'
              //     },
              //   }).then(resC => {
              //     console.log(resC)
              //   })
              //   // fetch(url, { headers , "withCredentials": "true"})
              //   //     .then((response) => {
              //   //       console.log(response)
              //   //       // Gérez la réponse ici
              //   //       window.open(url, "_blank");
              //   //     })
              //   //     .catch((error) => {
              //   //       // Gérez les erreurs ici
              //   //       console.error(error);
              //   //     });
              // }}
          >


    <i
            style={{ color: "#955cd5", cursor: "pointer" }}
            className="fas fa-file-download"
            // onClick={() => handleDownloadClick(row)}
          ></i>
       </a>
      ),
    },
  ]

  const { SearchBar } = Search

  const search = async () => {
    if (selectDepartement.value != "" || selectDepartement.value != null) {
      setOrders([])
      const res = await API.post("emploi/enseignant/search", {
        semestre_id: props.semestre,
        departement_id:selectDepartement.value
      }).then(res => {
        setOrders(res.data.enseignants)
      })
    }else{
      toast.error("⛔ département est obligatroire !", {
        containerId: "A",
      })
    }
  }
  const searchAll = async () => {
    const res = await API.post("emploi/enseignant/list", {
      semestre_id: props.semestre,
    }).then(res => {
      setOrders(res.data.enseignants)
      setLoading(true)
    })
  }
  const vider = async () => {
     setSelectDepartement("")
  }
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <div>
                      <CardTitle style={{ color: "#556ee6" }} className="h4">
                        Critéres de Recherches
                      </CardTitle>
                      <Form className="mt-4">
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input2">Département :</Label>
                              <Select
                                  options={departement}
                                  isSearchable={true}
                                  // isClearable={true}
                                  value={selectDepartement}
                                  onChange={e => setSelectDepartement(e)}
                              />
                            </div>
                          </Col>


                        <Col lg="6" style={{padding: "26px 35px"}}>
                            <button
                                type="button"
                                style={{
                                  backgroundColor: "#761C19",
                                  borderColor: "#761C19",
                                }}
                                className="btn btn-success mb-2 me-2"
                                onClick={search}
                            >
                              Rechercher
                            </button>
                            <button
                                type="button"
                                style={{
                                  backgroundColor: "#761C19",
                                  borderColor: "#761C19",
                                }}
                                className="btn btn-success mb-2 me-2"
                                onClick={searchAll}
                            >
                              Afficher tout
                            </button>

                        </Col>

                        </Row>
                      </Form>

                      <div className="mb-12 row" style={{ display: "flex" }}>
                        <div className="col-md-6 mb-3 row">
                          <div className="col-md-12 search-box-etd">
                            <div
                              className="search-box me-2 mb-2 d-inline-block"
                              style={{ width: "100%" }}
                            >
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            // display: "flex",
                            // justifyContent: "flex-end",
                          }}
                          className="col-md-6 mb-3 row"
                        >
                          <div
                            style={{ textAlign: "right" ,display:'contents'}}
                            className="col-md-6 select-action"
                          >
                            {
                              selectDepartement.value ?(
                                  <a className="col-md-6"
                                      href={
                                          "https://scolarite.backcresus-institut.ovh/api/impression_enseignants_departement_shedules" + "/" +selectDepartement.value +"/"+props.semestre+"/"+annee
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                  >
                                    <button className="btn btn-primary btn btn-primary">
                                      <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                      Emplois enseignants par département
                                    </button>
                                  </a>
                              ):(
                                  <a
                                      className="col-md-6"
                                      target="_blank"
                                      rel="noreferrer"
                                  >
                                    <button className="btn btn-primary btn btn-primary">
                                      <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                      Emplois enseignants par département
                                    </button>
                                  </a>
                              )}

                            <a style={{margin:"0px 10px"}}
                              href={
                                "https://scolarite.backcresus-institut.ovh/api/impression_enseignants_shedules" + "/" + props.semestre+ "/" +annee
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button className="btn btn-primary btn btn-primary">
                                <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                Emplois enseignants
                              </button>
                            </a>


                          </div>
                        </div>
                      </div>
                    </div>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              En cours ...
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableEns)
DataTableEns.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
}
