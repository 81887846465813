import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import API from "../../api"
import classnames from "classnames"
import ListEtudiant from "./ListEtudiant"
import FileDownload from "js-file-download";
import axios from "axios";

const ListSalle = props => {
  const state_element = props.location.state.state_element
  const element = props.location.state.element
  const niveau_id = props.location.state.niveau_id
  const [section, setSection] = useState()
  const [listSalle, setListSalle] = useState([])

  useEffect(async () => {
    const res = await API.post("examen/final/list_salle", {
      examen_id: state_element.id,
    }).then(res => {
      setListSalle(res.data.list)
    })
  }, [])

  const renderBlock = section => {
    for (let i = 0; i < listSalle.length; i++) {
      if (section === i) {
        return (
          <ListEtudiant
            listSalle={listSalle[i]}
            element={element}
            niveau_id={niveau_id}
            state_element={state_element}
          />
        )
      }
    }
  }
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.Annee.id
  // const uploadPDFTotal = async () => {
  //   const res = await API.post(`pdf/impression_list_vierge_emargement_total` ,{ salle: listSalle ,annee_id:annee,examen_id: state_element.id}).then(res => {
  //     FileDownload(res.data,  "بيانات ساعات التدريس التكميلية.xlsx")
  //   })
  // }
  // const downloadPDF = async () => {
  //   try {
  //     const response = await API.post('pdf/impression_list_vierge_emargement_total', { salle: listSalle, annee_id: annee ,examen_id: state_element.id}, { responseType: 'blob' });
  //     const blob = new Blob([response.data], { type: 'application/pdf' });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'Fiche d\'émargement et de notes.pdf');
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } catch (error) {
  //     console.error('Erreur lors du téléchargement du fichier PDF :', error);
  //   }
  // };

  // const uploadPDF = async () => {
  //   // window.open('https://scolarite.backcresus-institut.ovh/api/pdf/impression_list_vierge_emargement_total/'+annee,'_blank')
  //   // const res = await API.post(`pdf/impression_list_vierge_emargement_total`,
  //   //     { salle: listSalle, annee_id: annee ,examen_id: state_element.id}).then(res => {
  //   //   FileDownload(res.data, "nom_du_fichier.pdf");
  //   // }).catch(error => {
  //   //   // Gérer les erreurs
  //   //   console.error("Une erreur s'est produite lors du téléchargement du fichier PDF :", error);
  //   // });
  //   // try {
  //   //   const formData = new FormData();
  //   //   formData.append('salle', listSalle);
  //   //   formData.append('annee_id', annee);
  //   //   formData.append('examen_id', state_element.id);
  //   //
  //   //   const response = await fetch('https://scolarite.backcresus-institut.ovh/api/pdf/impression_list_vierge_emargement_total', {
  //   //     method: 'POST',
  //   //     body: formData,
  //   //     headers: {
  //   //       'Content-Type': 'multipart/form-data' // Spécifier le type de contenu
  //   //     }
  //   //   });
  //   //
  //   //   const blob = await response.blob();
  //   //   const url = window.URL.createObjectURL(blob);
  //   //
  //   //   // Ouvrir le lien de téléchargement dans une nouvelle fenêtre
  //   //   window.open(url, '_blank');
  //   // } catch (error) {
  //   //   console.error("Une erreur s'est produite lors de la génération du PDF :", error);
  //   // }
  //   API.post(
  //       "pdf/impression_list_vierge_emargement_total",
  //       { salle: listSalle, annee_id: annee ,examen_id: state_element.id},
  //       { responseType: "blob" }
  //   ).then(res => {
  //     const contentDisposition = res.headers["content-disposition"];
  //     const match = /filename="(.+)"/.exec(contentDisposition);
  //     const fileName = match ? match[1] : `Fiche d'émargement et de notes.pdf`;
  //     const blob = new Blob([res.data], { type: res.data.type });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(a);
  //   });
  // }

  const uploadPDF = async () => {
    // try {
    //   const response = await API.post(
    //       "pdf/impression_list_vierge_emargement_total",
    //       { salle: listSalle, annee_id: annee, examen_id: state_element.id },
    //       { responseType: "blob" ,"Access-Control-Allow-Origin":"*"}
    //   );
    //
    //   const contentDisposition = response.headers["content-disposition"];
    //   const match = /filename="(.+)"/.exec(contentDisposition);
    //   const fileName = match ? match[1] : `Fiche d'émargement et de notes.pdf`;
    //
    //   // Create a Blob object from the response data
    //   const blob = new Blob([response.data], { type: response.data.type });
    //
    //   // Create a URL for the Blob
    //   const url = window.URL.createObjectURL(blob);
    //
    //   // Create a hidden link and set the necessary attributes
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = fileName;
    //   a.style.display = "none"; // Hide the link
    //
    //   // Append the link to the document body
    //   document.body.appendChild(a);
    //
    //   // Trigger the download
    //   a.click();
    //
    //   // Clean up by revoking the URL and removing the link from the document body
    //   window.URL.revokeObjectURL(url);
    //   document.body.removeChild(a);
    // } catch (error) {
    //   console.error("Error while uploading PDF:", error);
    //   // Handle the error here, for example, display a message to the user
    // }
    // try {
    //   const response = await axios.put(
    //       "https://scolarite.backcresus-institut.ovh/api/pdf/impression_list_vierge_emargement_total",
    //       {
    //         salle: listSalle,
    //         annee_id: annee,
    //         examen_id: state_element.id
    //       },
    //       {
    //         responseType: "blob",
    //         headers: {
    //           Accept: "application/pdf, text/plain, */*," ,// Modifier l'en-tête Accept
    //           "Access-Control-Allow-Origin":"http://localhost:3001",
    //           "access-control-allow-credentials":true,
    //         }
    //       }
    //   );
    //
    //   // Create a Blob from the response data
    //   const pdfBlob = new Blob([response.data], {type: "application/pdf"});
    //
    //   // Create a temporary URL for the Blob
    //   const url = window.URL.createObjectURL(pdfBlob);
    //
    //   // Create a temporary <a> element to trigger the download
    //   const tempLink = document.createElement("a");
    //   tempLink.href = url;
    //   tempLink.setAttribute(
    //       "download",
    //       `Fiche d\'émargement et de notes.pdf`
    //   ); // Set the desired filename for the downloaded file
    //
    //   // Append the <a> element to the body and click it to trigger the download
    //   document.body.appendChild(tempLink);
    //   tempLink.click();
    //
    //   // Clean up the temporary elements and URL
    //   document.body.removeChild(tempLink);
    //   window.URL.revokeObjectURL(url);
    // } catch (error) {
    //   console.error("Error downloading PDF:", error);
    // }
    try {
      // Vérifier si listSalle est un tableau
      if (!Array.isArray(listSalle)) {
        // Si listSalle n'est pas un tableau, afficher un message d'erreur
        console.error("Erreur : listSalle n'est pas un tableau.");
        return;
      }

      // Convertir listSalle en une chaîne JSON
      const salleJson = JSON.stringify(listSalle);

      // Envoyer les données à l'API avec Axios
      const response = await axios.post("https://scolarite.backcresus-institut.ovh/api/impression_list_vierge_emargement_total", {
        salle: listSalle,
        annee_id: annee,
        examen_id: state_element.id
      }, {
        responseType: 'blob' // Indique que vous attendez un téléchargement de fichier (PDF)
      });

      // Vérifier si la requête a réussi
      if (response.status === 200) {
        // Créer un objet URL pour le blob (PDF)
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Créer un lien invisible pour télécharger le PDF
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Fiche d\'émargement et de notes.pdf'); // Nom du fichier PDF
        document.body.appendChild(link);
        link.click();

        // Nettoyer l'URL créé après le téléchargement
        window.URL.revokeObjectURL(url);

        console.log("Téléchargement du PDF réussi !");
      } else {
        // Gérer les erreurs en cas d'échec de la requête
        console.error("Erreur lors du téléchargement du PDF :", response.statusText);
      }
    } catch (error) {
      console.error("Erreur :", error);
    }
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="text-sm-end">
                <div className="text-center mt-4">

                  {/*<a  className="btn mb-2 me-2" onClick={uploadPDF}*/}
                  {/*    style={{ borderColor: "#7d7b7b",width:"10%" }}*/}
                  {/*    target="_blank"*/}
                  {/*    rel="noreferrer"*/}
                  {/*><i*/}
                  {/*    style={{ color: "#a21c1c", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                  {/*    className="fas fa-file-pdf"*/}
                  {/*></i>*/}
                  {/*  <p> {"EXPORT"}</p>*/}
                  {/*</a>*/}
                  <button
                      className="btn mb-2 me-2"
                      onClick={uploadPDF}
                      style={{ borderColor: "#7d7b7b", width: "10%" }}
                  >
                    <i
                        style={{ color: "#a21c1c", cursor: "pointer", fontSize: "30px", margin: "0px 6px 0px 0px" }}
                        className="fas fa-file-pdf"
                    ></i>
                    <p>{"EXPORT"}</p>
                  </button>

                </div>
              </div>
              <Nav className="icon-tab nav-justified mt-3 mb-3">
                {listSalle.map((element, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === element.section,
                      })}
                      onClick={() => {
                        setSection(element.section)
                      }}
                    >
                      <span className="d-none d-sm-block">{element.salle}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              {renderBlock(section)}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ListSalle
ListSalle.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
