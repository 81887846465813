import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
    Row,
    Col,
    Spinner
    , Label, Form, CardTitle,
} from "reactstrap"
import { withRouter } from "react-router"
import Select from "react-select";
import {Slide, toast, ToastContainer} from "react-toastify";
import FileDownload from "js-file-download"
const DataTablePlanEtude = props => {
  const [loading, setLoading] = useState(false)
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [specialite, setSpecialite] = useState([])
    const [annee, setAnnee] = useState("");
    useEffect(async () => {
        var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
        var annee_id = anneeScolaire.Annee.id
        setAnnee(annee_id)
    // cycle
    const res = await API.get("cycle/select").then(res => {
      setCycle(res.data.Cycle)
    })
    //niveau
    const resNiveau = await API.get("niveau/select").then(resNiveau => {
      setNiveau(resNiveau.data.Niveau)
    })
    setLoading(true)
  }, [])
    const getSpecialite = async event => {
        setSelectNiveau(event)
        setSelectSpecialite("")
        const res = await API.post("specialite/get_by_niveaux_cycle", {
             niveau_id: event.value,
            cycle_id: selectCycle.value,
        }).then(res => {
            setSpecialite(res.data.Specialites)
        })
    }
    const cycles =async  event =>{
      setSelectCycle(event)
        if(event.value == 2 || event.value == 3){
            setNiveau([
                { value: 1, label: "1 ère Année" },
                { value: 2, label: "2 ème Année " },
            ])
        }else{
            API.get("niveau/select").then(resNiveau => {
                setNiveau(resNiveau.data.Niveau)
            })
        }
      setSelectNiveau("")
      setSelectSpecialite("")
    }

    const uploadPDF = async () => {

        var cycle_id = selectCycle != "" && selectCycle != null ? selectCycle.value : 0
        var niveau_id = selectNiveau != "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite != "" && selectSpecialite != null ? selectSpecialite.value : 0
        // alert(cycle_id)
        if(cycle_id == 0 || niveau_id == 0 || specialite_id == 0){
            setLoading(true)
            toast.error("⛔  Cycle , Niveau et Spécialite sont obligatoire pour le PDF de plan d'etude !", {
                containerId: "A",
            })
        }else{
            window.open('https://scolarite.backcresus-institut.ovh/api/pdf/plan_etudes/'
                +niveau_id+'/'+ cycle_id+ '/' + specialite_id +'/'+annee
                ,'_blank')
        }
    }
    const uploadExcel = async () => {

        var cycle_id = selectCycle != "" && selectCycle != null ? selectCycle.value : 0
        var niveau_id = selectNiveau != "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite != "" && selectSpecialite != null ? selectSpecialite.value : 0
        // alert(cycle_id)
        if(cycle_id == 0 || niveau_id == 0 || specialite_id == 0){
            setLoading(true)
            toast.error("⛔  Cycle , Niveau et Spécialite sont obligatoire pour le PDF de plan d'etude !", {
                containerId: "A",
            })
        }else{
            const res = await API.get(
                `excel/plan_etudes/${niveau_id}/${cycle_id}/${specialite_id}/${annee}` ,
                { responseType: "blob" }
            ).then(res => {
                FileDownload(res.data,  "ﻣﺨﻄﻂ اﻟﺪراﺳﺎت.xlsx")
            })

        }
    }
    const uploadExcelRepartition = async () => {

        var cycle_id = selectCycle != "" && selectCycle != null ? selectCycle.value : 0
        var niveau_id = selectNiveau != "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite != "" && selectSpecialite != null ? selectSpecialite.value : 0
        // alert(cycle_id)
        if(cycle_id == 0 || niveau_id == 0 || specialite_id == 0){
            setLoading(true)
            toast.error("⛔  Cycle , Niveau et Spécialite sont obligatoire pour le PDF de plan d'etude !", {
                containerId: "A",
            })
        }else{
            const res = await API.get(
                `excel/repartition_groupes_enseignants_by_matiere_scolaire/${niveau_id}/${cycle_id}/${specialite_id}/${annee}` ,
                { responseType: "blob" }
            ).then(res => {
                FileDownload(res.data,  "توزيع المجموعات و المدرسين حسب المادة المدرسية.xlsx")
            })

        }
    }

    const uploadPDFRepartition = async () => {

        var cycle_id = selectCycle != "" && selectCycle != null ? selectCycle.value : 0
        var niveau_id = selectNiveau != "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite != "" && selectSpecialite != null ? selectSpecialite.value : 0
        // alert(cycle_id)
        if(cycle_id == 0 || niveau_id == 0 || specialite_id == 0){
            setLoading(true)
            toast.error("⛔  Cycle , Niveau et Spécialite sont obligatoire pour le PDF de plan d'etude !", {
                containerId: "A",
            })
        }else{
            window.open('https://scolarite.backcresus-institut.ovh/api/pdf/repartition_groupes_enseignants_by_matiere_scolaire/'
                +niveau_id+'/'+ cycle_id+ '/' + specialite_id +'/'+annee
                ,'_blank')
        }
    }
    const uploadPDFRepartitionNombre = async () => {
      window.open('https://scolarite.backcresus-institut.ovh/api/pdf/etudiant_groups_cycle_specialite/'+annee,'_blank')
    }
    const uploadExcelRepartitionNombre = async () => {
        const res = await API.get(`excel/etudiant_groups_cycle_specialite/${annee}` ,{ responseType: "blob" }).then(res => {
            FileDownload(res.data,  "عدد و توزيع الطلبة.xlsx")
        })
    }
    const uploadPDFHeureSupp = async () => {
      window.open('https://scolarite.backcresus-institut.ovh/api/pdf/calcul_charge_horaires_permanant/'+annee,'_blank')
    }
    const uploadExcelHeureSupp = async () => {
        const res = await API.get(`excel/calcul_charge_horaires_permanant/${annee}` ,{ responseType: "blob" }).then(res => {
            FileDownload(res.data,  "بيانات ساعات التدريس التكميلية.xlsx")
        })
    }
    const uploadPDFEnseignantPer = async () => {
      window.open('https://scolarite.backcresus-institut.ovh/api/pdf/list_enseignant_permanant_charge_horaires_supp/'+annee,'_blank')
    }
    const uploadExcelEnseignantPer = async () => {
        const res = await API.get(`excel/list_enseignant_permanant_charge_horaires_supp/${annee}` ,{ responseType: "blob" }).then(res => {
            FileDownload(res.data,  "قائمة في المدرسين القارين والمتعاقدين الذين انجزوا ساعات تكميلية.xlsx")
        })
    }
    const uploadPDFEnseignantVac = async () => {
      window.open('https://scolarite.backcresus-institut.ovh/api/pdf/calcul_charge_horaires_vacataire/'+annee,'_blank')
    }
    const uploadExcelEnseignantVac = async () => {
        const res = await API.get(`excel/calcul_charge_horaires_vacataire/${annee}` ,{ responseType: "blob" }).then(res => {
            FileDownload(res.data,  "قائمة في المدرسين العرضيين.xlsx")
        })
    }
    const uploadPDFEnseignantPerCont = async () => {
      window.open('https://scolarite.backcresus-institut.ovh/api/pdf/enseignant_permanant/'+annee,'_blank')
    }
    const uploadExcelEnseignantPerCont = async () => {
        const res = await API.get(`excel/enseignant_permanant/${annee}` ,{ responseType: "blob" }).then(res => {
            FileDownload(res.data,  "قائمة في المدرسين القارين و المتعاقدين.xlsx")
        })
    }
    const uploadPDFEnseignantCont= async () => {
      window.open('https://scolarite.backcresus-institut.ovh/api/pdf/calcul_charge_horaires_permanant/'+annee,'_blank')
    }
    const uploadExcelEnseignantCont = async () => {
        const res = await API.get(`excel/calcul_charge_horaires_permanant/${annee}` ,{ responseType: "blob" }).then(res => {
            FileDownload(res.data,  "جدول بياني في ساعات التدريس للمدرسين القارين و المتعاقدين المؤمنة للسنة الجامعية.xlsx")
        })
    }
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Form>
                <CardTitle style={{ color: "#556ee6" }} className="h4">
                    Critéres de Recherches
                </CardTitle>
              <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Cycle</Label>
                <Select
                    options={cycle}
                    isSearchable={true}
                    isClearable={true}
                    value={selectCycle}
                    onChange={e => cycles(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Niveau</Label>
                <Select
                    options={niveau}
                    isSearchable={true}
                    isClearable={true}
                    value={selectNiveau}
                    onChange={e => getSpecialite(e)}
                />
              </div>
            </Col>
          </Row>
                <Row>
                      <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Spécialité</Label>
                            <Select
                                options={specialite}
                                isSearchable={true}
                                isClearable={true}
                                value={selectSpecialite}
                                onChange={e => setSelectSpecialite(e)}
                            />
                        </div>
                    </Col>

                </Row>
                {/*<Row>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}

                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn  mb-2 me-2" onClick={uploadPDF}*/}
                {/*                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-pdf"*/}
                {/*            ></i>*/}
                {/*               <p> {"ﻣﻠﺤﻖ ﻋﺪد 02 "}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}
                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn mb-2 me-2" onClick={uploadExcel}*/}
                {/*                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-excel"*/}
                {/*            ></i>*/}
                {/*               <p> {"ﻣﻠﺤﻖ ﻋﺪد 02 "}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}

                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn  mb-2 me-2" onClick={uploadPDFRepartitionNombre}*/}
                {/*                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-pdf"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﻣﻠﺤﻖ ﻋﺪد 01"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}
                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn mb-2 me-2" onClick={uploadExcelRepartitionNombre}*/}
                {/*                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-excel"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﻣﻠﺤﻖ ﻋﺪد 01"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}

                {/*    </Col>*/}
                {/*</Row>*/}

                <Row>
                    <Col lg="3" style={{textAlign :"center"}}>

                        <div className="text-center mt-4">
                            <a  className="btn  mb-2 me-2" onClick={uploadPDFRepartition}
                                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}
                                target="_blank"
                                rel="noreferrer"
                            ><i
                                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}
                                className="fas fa-file-pdf"
                            ></i>
                                <p> {"ﺟﺪول ﻋﺪد 03"}</p>
                            </a>
                        </div>
                    </Col>
                    <Col lg="3" style={{textAlign :"center"}}>
                        <div className="text-center mt-4">
                            <a  className="btn mb-2 me-2" onClick={uploadExcelRepartition}
                                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}
                                target="_blank"
                                rel="noreferrer"
                            ><i
                                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}
                                className="fas fa-file-excel"
                            ></i>
                                <p> {"ﺟﺪول ﻋﺪد 03"}</p>
                            </a>
                        </div>

                    </Col>
                    <Col lg="3" style={{textAlign :"center"}}>

                        <div className="text-center mt-4">
                            <a  className="btn  mb-2 me-2" onClick={uploadPDFHeureSupp}
                                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}
                                target="_blank"
                                rel="noreferrer"
                            ><i
                                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}
                                className="fas fa-file-pdf"
                            ></i>
                                <p> {"ﻣﻠﺤﻖ ﻋﺪد 03"}</p>
                            </a>
                        </div>
                    </Col>
                    <Col lg="3" style={{textAlign :"center"}}>
                        <div className="text-center mt-4">
                            <a  className="btn mb-2 me-2" onClick={uploadExcelHeureSupp}
                                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}
                                target="_blank"
                                rel="noreferrer"
                            ><i
                                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}
                                className="fas fa-file-excel"
                            ></i>
                                <p> {"ﻣﻠﺤﻖ ﻋﺪد 03"}</p>
                            </a>
                        </div>

                    </Col>
                </Row>

                {/*<Row>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}

                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn  mb-2 me-2" onClick={uploadPDFEnseignantPer}*/}
                {/*                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-pdf"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﻣﻠﺤﻖ ﻋﺪد 04"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}
                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn mb-2 me-2" onClick={uploadExcelEnseignantPer}*/}
                {/*                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-excel"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﻣﻠﺤﻖ ﻋﺪد 04"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}

                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}

                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn  mb-2 me-2" onClick={uploadPDFEnseignantVac}*/}
                {/*                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-pdf"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﺟﺪول ﻋﺪد 02"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}
                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn mb-2 me-2" onClick={uploadExcelEnseignantVac}*/}
                {/*                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-excel"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﺟﺪول ﻋﺪد 02"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}

                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}

                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn  mb-2 me-2" onClick={uploadPDFEnseignantPerCont}*/}
                {/*                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-pdf"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﺟﺪول ﻋﺪد 04"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}
                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn mb-2 me-2" onClick={uploadExcelEnseignantPerCont}*/}
                {/*                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-excel"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﺟﺪول ﻋﺪد 04"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}

                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}

                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn  mb-2 me-2" onClick={uploadPDFEnseignantCont}*/}
                {/*                style={{ height:"80px",borderColor: "#7d7b7b", width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "#761C19", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-pdf"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﺟﺪول ﺑﻴﺎﻧﻲ ﻓﻲ ﺳﺎﻋﺎت اﻟﺘﺪرﻳﺲ"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col lg="3" style={{textAlign :"center"}}>*/}
                {/*        <div className="text-center mt-4">*/}
                {/*            <a  className="btn mb-2 me-2" onClick={uploadExcelEnseignantCont}*/}
                {/*                style={{ height:"80px",backgroundColor:"none", borderColor: "#7d7b7b",width:"100%" }}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*            ><i*/}
                {/*                style={{ color: "green", cursor: "pointer", fontSize: "30px" , margin:"0px 6px 0px 0px" }}*/}
                {/*                className="fas fa-file-excel"*/}
                {/*            ></i>*/}
                {/*                <p> {"ﺟﺪول ﺑﻴﺎﻧﻲ ﻓﻲ ﺳﺎﻋﺎت اﻟﺘﺪرﻳﺲ"}</p>*/}
                {/*            </a>*/}
                {/*        </div>*/}

                {/*    </Col>*/}
                {/*</Row>*/}
            </Form>


          </Row>

        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
    </React.Fragment>
  )
}

export default withRouter(DataTablePlanEtude)
DataTablePlanEtude.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
