import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {Col, Input, Row, Label, Form, Button, Progress} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import Select from "react-select";

const AddResultatExamen = props => {
    const [disbutReg, setDisbutReg] = useState(true)
    const [file, setFile] = useState(null)
    const [uploadPercent, setUploadPercent] = useState(0)
    const [semestre, setSemestre] = useState([
        { value: 1, label: "Semestre 1" },
        { value: 2, label: "Semestre 2" },
        { value: 3, label: "Session Contrôle" },
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    if (localStorage.getItem("anneeScolaire")) {
        var anneeScolaire = JSON.parse(
            localStorage.getItem("anneeScolaire")
        )
        var annee_id = anneeScolaire.Annee.id
    }
    const addFile = e => {
        var file = e.target.files[0]
        setFile(file)
    }

    const uploadFile = async () => {

        if(selectSemestre == "" || file ==null){
            setDisbutReg(false)
            toast.error("⛔ Tous les champs sont obligatoire !", {
                containerId: "A",
            })
        }else{
            setDisbutReg(true)
            let data = new FormData()
            data.append("zip", file)
            data.append("semestre_id", selectSemestre.value)
            data.append("annee_id", annee_id)
            const options = {
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent
                    let percent = Math.round((loaded * 100) / total)
                    if (percent < 100) {
                        setUploadPercent(percent)
                    }
                },
            }
            API.post("zip", data, options).then(res => {
                if (res.data.status == 200) {
                    setUploadPercent(100)
                    props.setSection(1)
                } else {
                    setDisbutReg(true)
                    toast.error(
                        "⛔ Il existe un problème d'insertion",
                        {
                            containerId: "A",
                        }
                    )
                }
            })
        }

    }

    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Semestre : </Label>
                                <Select
                                    options={semestre}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectSemestre(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Fichier (.ZIP) :</Label>
                                <Input
                                    onChange={e => addFile(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".rar,.zip"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {uploadPercent > 0 && uploadPercent < 100 ? (
                            <Progress
                                striped
                                className="progress-xl"
                                color="success"
                                value={uploadPercent}
                            >
                                {uploadPercent}%
                            </Progress>
                        ) : null}
                    </Row>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >
                            <Button
                                type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                onClick={props.back}
                            >
                                {props.t("Annuler")}
                            </Button>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                            className="text-center mt-4"
                        >
                            <button type="button" className="btn btn-primary "  disabled={!disbutReg} onClick={uploadFile}>
                                {props.t("Confirmer")}
                            </button>
                        </div>
                    </Col>
                </div>
            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withTranslation()(AddResultatExamen)
AddResultatExamen.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
}
