import React, { useState } from "react"
import { Button, Col, Form, Input, Label, Progress, Row } from "reactstrap"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import Select from "react-select"

const ImportationSurveillance = props => {
  const [file, setFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
    { value: 3, label: "Session Contrôle" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const uploadFile = async () => {
    console.log(selectSemestre.value)
    let data = new FormData()
    data.append("file", file)
    data.append("semestre_id", selectSemestre.value)
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        if (percent < 100) {
          setUploadPercent(percent)
        }
      },
    }
    API.post("surveillance/import", data, options).then(res => {
      if (res.data.status == 200) {
        setUploadPercent(100)
        props.setSection(1)
      } else {
        toast.error(
          "⛔ Il existe un enseignant qui a une CIN n'existe pas dans la base",
          {
            containerId: "A",
          }
        )
      }
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Semestre</Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  value={selectSemestre}
                  onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">File :</Label>
                <Input
                  onChange={e => addFile(e)}
                  className="form-control"
                  type="file"
                  id="formFile"
                />
              </div>
            </Col>
          </Row>
          <Row>
            {uploadPercent > 0 && uploadPercent < 100 ? (
              <Progress
                striped
                className="progress-xl"
                color="success"
                value={uploadPercent}
              >
                {uploadPercent}%
              </Progress>
            ) : null}
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button
                type="button"
                className="btn btn-primary "
                onClick={uploadFile}
              >
                Upload
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default ImportationSurveillance
ImportationSurveillance.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
