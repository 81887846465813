import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddModule = props => {
  const [arrayModule, setArrayModule] = useState([{ name: "" }])
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")

  const addLigne = () => {
    let element = {
      name: "",
    }
    setArrayModule([...arrayModule, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayModule.splice(indexLigne, 1)
    setModal(false)
  }

  const addModule = (event, index) => {
    let name = event.target.value
    setArrayModule(
      arrayModule.map((el, id) =>
        id === index ? Object.assign(el, { name: name }) : el
      )
    )
  }

  const save = async () => {
    var etat = true
    for (let i = 0; i < arrayModule.length; i++) {
      var module = arrayModule[i].name
      if (module == "") {
        var etat = false
        toast.error("⛔ Remplir les champs vides", {
          containerId: "A",
        })
      }
    }
    if (arrayModule.length == 0) {
      var etat = false
      toast.error("⛔ Saisir au moins un module", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await API.post("module/add", {
        modules: arrayModule,
      }).then(res => {
        props.setSection(1)
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <CardTitle className="h4">Ajouter module</CardTitle>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Module
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                <i
                  style={{
                    color: "#a0ceb8",
                    cursor: "pointer",
                  }}
                  className="fas fa-plus"
                  onClick={addLigne}
                />
              </th>
            </tr>
          </thead>
          {arrayModule.map((el, index) => (
            <tbody key={index}>
              <tr>
                <td
                  className="col-md-10"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Input
                    style={{ width: "50%", textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Module ..."
                    onChange={e => addModule(e, index)}
                  />
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "#cc0000" }}
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => toggle(index)}
                  />
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {"Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddModule
AddModule.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
