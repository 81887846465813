import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import ListExam from "./ListExam"

const NoteExamenControle = ({ semestre }) => {
  const [niveau, setNiveau] = useState([
    { value: 1, label: "1ére Année", section: 0 },
    { value: 2, label: "2éme Année", section: 1 },
    { value: 3, label: "3éme Année", section: 2 },
  ])
  const [section, setSection] = useState("")

  useEffect(() => {
    renderBlock()
  }, [semestre])

  const renderBlock = section => {
    for (let i = 0; i < niveau.length; i++) {
      if (section === i) {
        return <ListExam niveau={niveau[i]} semestre={semestre} session={2} />
      }
    }
  }

  return (
    <React.Fragment>
      <Nav className="icon-tab nav-justified mt-3 mb-3">
        {niveau.map((element, index) => (
          <NavItem key={index}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: section === element.section,
              })}
              onClick={() => {
                setSection(element.section)
              }}
            >
              <span className="d-none d-sm-block">{element.label}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>{" "}
      {renderBlock(section)}
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(NoteExamenControle))
NoteExamenControle.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
}
