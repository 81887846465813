import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import API from "../../api"
import classnames from "classnames"
import EditNote from "./EditNote"
import ConsultMoyen from "./ConsultMoyen"

const EditAbsence = props => {
  const state_element = props.location.state.element
  const enseignant_id = props.location.state.enseignant_id
  const date = props.location.state.date
  const type = props.location.state.type
  const [section, setSection] = useState()
  const [listGroupe, setListGroupe] = useState([])

  useEffect(async () => {
    const res = await API.post("seance/absance/list_group", {
      emploi_id: state_element.id_emploi,
      matiere_id: state_element.id_matiere,
      jour_id: state_element.id_jour,
      quart_id: state_element.id_quart,
      type: state_element.type,
    }).then(res => {
      setListGroupe(res.data.list)
    })
  }, [])

  const renderBlock = section => {
    for (let i = 0; i < listGroupe.length; i++) {
      if (section === i) {
        if (type == 1) {
          return (
            <ConsultMoyen
              listGroup={listGroupe[i]}
              matiere_id={state_element.id_matiere}
              type={state_element.type}
            />
          )
        } else {
          return (
            <EditNote
              listGroup={listGroupe[i]}
              emploi_id={state_element.id_emploi}
              matiere_id={state_element.id_matiere}
              jour_id={state_element.id_jour}
              quart_id={state_element.id_quart}
              type={state_element.type}
              enseignant_id={enseignant_id}
              date={date}
            />
          )
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4" style={{ color: "#556EE6" }}>
                Modification note & présence par seance
              </CardTitle>
              <Nav className="icon-tab nav-justified mt-3 mb-3">
                {listGroupe.map((element, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === element.section,
                      })}
                      onClick={() => {
                        setSection(element.section)
                      }}
                    >
                      <span className="d-none d-sm-block">
                        {element.label_group}
                      </span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              {renderBlock(section)}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditAbsence
EditAbsence.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
